<template>
    <div
        @mousedown="sec = 0"
        @mousemove="sec = 0"
        @mouseup="sec = 0"
        @mouseenter="sec = 0"
        @mouseleave="sec = 0"
        @touchstart="sec = 0"
        @touchmove="sec = 0"
        @touchcancel="sec = 0"
        @touchend="sec = 0"
        @scrollstart="sec = 0"
        @scroll="sec = 0"
        @scrollend="sec = 0"
    >
        <div class="product-page" :class="isShowProduct || isCheaperTogether ? `blur` : false">
            <div class="dishes pt">
                <div class="dishes__container _container">
                    <div class="dishes__head">
                        <div class="btn-navs">
                            <t-btn-arrow @click="$router.push('/welcome')" />
                            <div class="search md" @click="isPopupKeyboad = !isPopupKeyboad">
                                <img :src="require(`@/assets/img/svg/search.svg`)" alt="search" />
                            </div>
                        </div>
                        <h1 class="title">
                            <!-- Заказ <span>{{ orderObj.order_type_name }}</span> -->
                            {{
                                orderObj.order_type_name === "С собой"
                                    ? $t("catalog.order_type_name2")
                                    : $t("catalog.order_type_name1")
                            }}
                        </h1>
                        <div class="btn-navs mm">
                            <div class="search" @click="isPopupKeyboad = !isPopupKeyboad">
                                <img :src="require(`@/assets/img/svg/search.svg`)" alt="search" />
                            </div>
                        </div>
                        <!-- <div class="t-coin">
            <div class="img">
              <img :src="require(`@/assets/img/coin.png`)" alt="coin" />
            </div>
            <div class="text">{{ $t("earn_ticoin") }}</div>
          </div> -->
                    </div>
                    <div class="dishes__columns">
                        <aside class="sidebar" v-if="!excludeСategories">
                            <ul class="menu">
                                <li>
                                    <a
                                        type="button"
                                        class="btnFastSets"
                                        :class="isFastSets ? `_active` : false"
                                        @click="isFastSets = !isFastSets"
                                    >
                                        <span>
                                            {{ $t("catalog.menu.fast_sets") }}
                                            <!-- Быстрые наборы -->
                                        </span>
                                    </a>
                                </li>
                                <li v-for="category in categories" :key="category.id">
                                    <a
                                        @click="changeCategory(category.id), (isFastSets = false)"
                                        class="menu__item"
                                        :class="selectedCategoryId === category.id ? `active` : false"
                                    >
                                        {{ $t(`catalog.menu.item["${category.real_name}"]`) }}
                                        <!-- {{ category.real_name }} -->
                                    </a>
                                </li>
                            </ul>
                        </aside>
                        <div class="dishes__body">
                            <h1 class="dishes__title"></h1>
                            <div class="search-nothing" v-if="!products.length">
                                {{ searchNothing }}
                            </div>
                            <div class="fast-sets" v-else-if="isFastSets">
                                <div class="fast-sets__blocks">
                                    <div class="fast-sets__block" v-for="(i, index) in setsCount" :key="index">
                                        <div class="fast-sets__sets">
                                            <div
                                                class="fast-sets__set"
                                                v-for="(product, prIndex) in setProducts[index].products"
                                                :key="product.id"
                                            >
                                                <div class="fast-sets__img">
                                                    <img :src="product.image" :alt="product.real_name" />
                                                </div>
                                                <div class="fast-sets__body">
                                                    <h4 class="fast-sets__title">
                                                        {{
                                                            product.real_name.length > 20
                                                                ? product.real_name.substr(0, 20) + "..."
                                                                : product.real_name
                                                        }}
                                                    </h4>
                                                    <button
                                                        class="fast-sets__change"
                                                        @click="changeProductInSet(index, prIndex)"
                                                    >
                                                        <span>Заменить</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="fast-sets__to-pay" v-if="inCart.indexOf(index) === -1">
                                            <button class="fast-sets__btn-to-pay" @click="sendOrder(index)">
                                                Купить за {{ setProducts[index].price }} ₽
                                            </button>
                                        </div>
                                        <div class="fast-sets__to-pay" v-if="inCart.indexOf(index) !== -1">
                                            <button
                                                class="fast-sets__btn-to-pay pressed"
                                                @click="this.$router.push('/cart/')"
                                            >
                                                Перейти к оплате
                                            </button>
                                        </div>
                                    </div>
                                    <div class="fast-sets__load">
                                        <button class="fast-sets__btn-load-more" @click="loadSets()">
                                            {{ load }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="blocks" v-else>
                                <div
                                    class="block"
                                    v-for="product in products"
                                    :product="product"
                                    :key="product.id"
                                    :class="product.is_popular ? `popular` : ``"
                                >
                                    <div class="block__img" @click="showFastSets(product.id)">
                                        <span class="tag hit" v-if="product.is_popular">Хит</span>
                                        <img
                                            :src="product.image ? product.image : require(`@/assets/img/${nophoto}`)"
                                            :alt="product.real_name"
                                        />
                                    </div>
                                    <div class="block__body">
                                        <h4 class="block__title">
                                            {{
                                                product.real_name.length > 30
                                                    ? product.real_name.substr(0, 30) + "..."
                                                    : product.real_name
                                            }}
                                        </h4>
                                        <div class="block__info">
                                            {{
                                                product.intro_text.length > 45
                                                    ? product.intro_text.substr(0, 45) + "..."
                                                    : product.intro_text
                                            }}
                                        </div>
                                        <div>
                                            <t-counter-price
                                                @change-order-item="changeOrderItem"
                                                :getOrderItem="getOrderItem"
                                                :orderObj="orderObj"
                                                :product="product"
                                                :showPrice="showPrice"
                                            />
                                            <!-- <t-add-to-cart
                      @add-to-order="addToOrder"
                      :orderObj="orderObj"
                      :product="product"
                    /> -->
                                            <div
                                                class="block-flex"
                                                v-if="
                                                    orderObj.products_ids.indexOf(product.id) == -1 &&
                                                    (product.modifiers.length === 0 || product.modifiers_as_hint)
                                                "
                                            >
                                                <div class="price">{{ product.price }} ₽</div>
                                                <div class="btn-green add-to-cart" @click="showFastSets(product.id)">
                                                    +
                                                </div>
                                            </div>
                                            <div
                                                @click="showProductId(product.id)"
                                                class="btn-green add-to-cart"
                                                v-if="product.modifiers.length > 0 && !product.modifiers_as_hint"
                                                style="width: 100%"
                                            >
                                                <span>{{ $t("btn_more") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <t-select-combo v-if="isShowCombo" />
            </div>
            <footer class="footer" @click="this.$router.push('/cart/')">
                <div class="footer__body">
                    <div class="footer__header">
                        <div class="footer__container _container">
                            <div class="footer__info">
                                <h2 class="footer__title">
                                    <img :src="require(`@/assets/img/svg/cart.svg`)" alt="cart" width="48" />
                                    <p>{{ totalCountCart }} {{ $t("catalog.items_cart") }}</p>
                                </h2>
                                <div class="total-sum__price">
                                    <span v-if="orderObj.discount_amount <= 0">{{ orderObj.full_price }}</span>
                                    <span v-if="orderObj.discount_amount > 0"
                                        ><span style="text-decoration: line-through">{{
                                            orderObj.products_price
                                        }}</span>
                                        {{ orderObj.full_price }}</span
                                    >
                                    ₽
                                    <img :src="require(`@/assets/img/svg/arrow-next.svg`)" alt="cart" width="13.47" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div class="keyboard-block popupSearch" v-if="isPopupKeyboad">
            <div class="keyboard">
                <div class="keyboard__input _container">
                    <div class="btn-find btn-circle" @click.stop>
                        <img src="@/assets/img/svg/find.svg" alt="find" width="30.9" />
                    </div>
                    <form class="search-input" @click.stop>
                        <div class="search-input__block">
                            <input
                                type="text"
                                name="q"
                                :placeholder="$t('catalog.find')"
                                class="search-input__input"
                                v-model="searchValue"
                                autocomplete="off"
                            />
                            <div class="search-input__remove" v-if="searchValue" @click="clearInput">
                                <img :src="require(`@/assets/img/svg/remove.svg`)" alt="remove" />
                            </div>
                        </div>
                    </form>
                    <button class="btn-circle" @click="isPopupKeyboad = false">
                        <img src="@/assets/img/svg/exit.svg" alt="exit" />
                    </button>
                </div>
                <div class="keyboard__content">
                    <div class="keyboard__nothing" v-if="!products.length && searchValue.length >= 3">
                        <div class="keyboard__loop">
                            <img src="@/assets/img/loop.png" alt="loop" />
                        </div>
                        <div class="keyboard__message">
                            {{ $t("catalog.nothing_result") }}
                        </div>
                    </div>
                    <div class="keyboard__result _container" v-else>
                        <div class="blocks" v-if="searchValue" @click.stop>
                            <div
                                class="block"
                                v-for="product in products"
                                :key="product.id"
                                :class="product.is_popular ? `popular` : ``"
                            >
                                <div class="block__img" @click="isCheaperTogether = true">
                                    <div class="tag hit" v-if="product.is_popular">Хит</div>
                                    <img :src="product.image" :alt="product.real_name" />
                                    <div class="count">
                                        {{ product.price }}
                                    </div>
                                </div>
                                <div class="block__body">
                                    <div class="block__title" @click="showProductId(product.id)">
                                        {{ product.real_name }}
                                    </div>
                                    <div>
                                        <t-counter-price
                                            @change-order-item="changeOrderItem"
                                            :getOrderItem="getOrderItem"
                                            :orderObj="orderObj"
                                            :product="product"
                                            :showPrice="showPrice"
                                        />
                                        <t-add-to-cart
                                            @add-to-order="addToOrder"
                                            :orderObj="orderObj"
                                            :product="product"
                                        />
                                        <div
                                            @click="showProductId(product.id)"
                                            class="btn-green add-to-cart"
                                            v-if="product.modifiers.length > 0 && !product.modifiers_as_hint"
                                            style="width: 100%"
                                        >
                                            <span>{{ $t("btn_more") }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="keyboard__tag">{{ $t("catalog.nothing") }}</div>
                </div>
                <div class="keyboard__block" @click.stop>
                    <div class="keyboard__container _container">
                        <div class="keyboard__body">
                            <div class="keyboard__keys" @click="typeLetter">
                                <div class="keyboard__row">
                                    <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                        {{ number }}
                                    </button>
                                    <button class="keyboard__key key">0</button>
                                    <button class="btn btn-lightgreen btn-long key">
                                        {{ $t("cart.btn_clear") }}
                                    </button>
                                </div>
                                <div class="keyboard__row">
                                    <button class="keyboard__key key" v-for="letter in letters1" :key="letter.id">
                                        {{ letter.char }}
                                    </button>
                                    <button class="btn btn-lightgreen btn-long2 key">
                                        <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                                    </button>
                                </div>
                                <div class="keyboard__row">
                                    <button class="keyboard__key key" v-for="letter in letters2" :key="letter.id">
                                        {{ letter.char }}
                                    </button>
                                    <button class="btn btn-lightgreen btn-long2 key">
                                        <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                                    </button>
                                </div>
                                <div class="keyboard__row">
                                    <button class="keyboard__key key" v-for="letter in letters3" :key="letter.id">
                                        {{ letter.char }}
                                    </button>
                                    <button class="btn btn-lightgreen btn-long key">ABC</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="productPopup" v-if="isShowProduct" @click="isShowProduct = false">
            <PopupProduct
                v-for="product in products"
                :key="product.id"
                :product="product"
                :product_id="product_id"
                @is-show-product="isShowProduct = false"
                @change-order-item="changeOrderItem"
                @add-to-order="addToOrder"
                :showPrice="showPrice"
                :getOrderItem="getOrderItem"
                @modifier-change="modifierChange"
                @modifier-add="modifierAdd"
                @handle-tab-click="handleTabClick"
                :currentTab="currentTab"
                :recommendedProducts="recommendedProducts"
                :orderObj="orderObj"
            />
        </div>
        <VirtualAssistant :orderObj="orderObj" @addToCart="addToOrder" />
        <div class="cheaper-together" v-if="isCheaperTogether">
            <div class="cheaper-together__popup">
                <div class="cheaper-together__contatiner">
                    <div class="cheaper-together__head">
                        <div class="cheaper-together__arrow-back btn-c">
                            <img src="@/assets/img/svg/arrow-back.svg" alt="arrow" width="10.11" />
                        </div>
                        <h2 class="cheaper-together__title">{{ $t("catalog.cheaper_title") }}</h2>
                        <div class="cheaper-together__close btn-c" @click="isCheaperTogether = false">
                            <img src="@/assets/img/svg/close.svg" alt="close" width="18.75" />
                        </div>
                    </div>
                    <div class="products" :style="fastSetsProducts.length > 3 ? 'height:60vh' : 'height:auto'">
                        <label
                            class="product"
                            v-for="(product, index) in fastSetsProducts"
                            :key="product.id"
                            :for="`product-${product.id}`"
                            @click="setPrice(product.price, product.id), (isHide = false)"
                        >
                            <div class="product__container">
                                <div class="product__img">
                                    <img
                                        :src="product.image ? product.image : require(`@/assets/img/${nophoto}`)"
                                        :alt="product.name"
                                    />
                                </div>
                                <div class="product__body">
                                    <h4 class="product__title">
                                        <!-- {{ $t(`catalog.set["${product.name}"]`) }} -->
                                        {{ product.name }}
                                    </h4>
                                    <div class="product__desc">
                                        {{ product.description }}
                                    </div>
                                    <div class="product__bottom">
                                        <div class="product__price">
                                            <div class="price">{{ product.price }} ₽</div>
                                            <!-- <div class="amount">{{ product.products_price }} ₽</div> -->
                                        </div>
                                        <input
                                            :id="`product-${product.id}`"
                                            type="radio"
                                            name="product"
                                            :value="product.id"
                                            :checked="index === 0 ? 'checked' : false"
                                        />
                                        <div class="product__check"></div>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <template v-for="(product, index) in products" :key="product.id">
                            <label
                                v-if="product.id === product_id"
                                class="product"
                                :for="`product-${product.id}`"
                                @click="isHide = true"
                            >
                                <div class="product__container">
                                    <div class="product__img">
                                        <img
                                            :src="product.image ? product.image : require(`@/assets/img/${nophoto}`)"
                                            :alt="product.real_name"
                                        />
                                    </div>
                                    <div class="product__body">
                                        <h4 class="product__title">{{ product.real_name }}</h4>
                                        <div class="product__desc">
                                            {{ product.description }}
                                        </div>
                                        <div class="product__bottom">
                                            <div class="product__price">
                                                <div class="price">{{ product.price }} ₽</div>
                                                <!-- <div class="amount">{{ product.products_price }} ₽</div> -->
                                            </div>
                                            <input
                                                :id="`product-${product.id}`"
                                                type="radio"
                                                name="product"
                                                :value="product.id"
                                                :checked="index === 0 ? 'checked' : false"
                                            />
                                            <div class="product__check"></div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </template>
                    </div>
                    <div class="cheaper-together__buttons">
                        <button class="cheaper-together__btn cancel" @click="isCheaperTogether = false">
                            {{ $t("catalog.buttons.not") }}
                        </button>
                        <button
                            v-if="!isHide"
                            class="cheaper-together__btn done"
                            @click="orderFastSetAdd(), (isCheaperTogether = false)"
                        >
                            {{ $t("catalog.buttons.add") }} {{ addPrice }}₽
                        </button>
                        <template v-if="isHide">
                            <template v-for="product in products" :key="product.id">
                                <button
                                    v-if="product.id === product_id"
                                    class="cheaper-together__btn done"
                                    @click="
                                        modifierAdd(currentTab.id, orderObj.id, 'increase', product.id),
                                            getOrderItem(product.id),
                                            (isCheaperTogether = false)
                                    "
                                >
                                    {{ $t("catalog.buttons.add") }} {{ product.price }}₽
                                </button>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <t-you-want-waiting v-if="isWaiting" @close="(isWaiting = false), (sec = 0)" :sec="sec" :countSec="countSec" />
    </div>
</template>

<script>
import PopupProduct from "@/components/PopupProduct.vue";
import VirtualAssistant from "@/components/VirtualAssistant.vue";
import axios from "axios";
import { api } from "@/api/api";

export default {
    components: { PopupProduct, VirtualAssistant },
    name: "t-dishes",
    data() {
        return {
            isWaiting: false,
            sec: 0,
            countSec: 25,
            timerInt: null,
            isHide: false,
            nophoto: "nophoto.jpg",
            isShowProduct: false,
            isCheaperTogether: false,
            isFastSets: false,
            isPopupKeyboad: false,
            isShowCombo: false,
            categories: [],
            selectedCategoryTitle: `Кыстыбургеры`,
            selectedCategoryId: 0,
            products: [],
            setProducts: [],
            info: `info.svg`,
            orderId: localStorage.getItem("orderId"),
            orderObj: {},
            excludeСategories: false,
            searchValue: "",
            searchNothing: `Загрузка блюд...`,
            cafe_slug: "",
            load: `Загрузить ещё...`,
            setsCount: 0,
            inCart: [],
            product_id: null,
            letters1: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
            ],
            letters2: [
                { id: 1, char: `ф` },
                { id: 2, char: `ы` },
                { id: 3, char: `в` },
                { id: 4, char: `а` },
                { id: 5, char: `п` },
                { id: 6, char: `р` },
                { id: 7, char: `о` },
                { id: 8, char: `л` },
                { id: 9, char: `д` },
                { id: 10, char: `ж` },
                { id: 11, char: `э` },
            ],
            letters3: [
                { id: 1, char: `я` },
                { id: 2, char: `ч` },
                { id: 3, char: `с` },
                { id: 4, char: `м` },
                { id: 5, char: `и` },
                { id: 6, char: `т` },
                { id: 7, char: `ь` },
                { id: 8, char: `б` },
                { id: 9, char: `ю` },
                { id: 10, char: `_` },
            ],
            isChangeOrder: true,
            isIndigrients: false,
            product: {},
            errors: [],
            currentTab: {},
            recommendedProducts: [],
            fastSetsProducts: [],
            addPrice: 0,
            fastSetId: 0,
            productId: 0,
            arrow: `arrow-up.svg`,
        };
    },
    methods: {
        showFastSets(product_id) {
            this.product_id = product_id;
            //быстрые наборы
            axios
                .get(api + `fast-sets/?product=${this.product_id}`)
                .then((response) => {
                    console.log(response.data);
                    this.fastSetsProducts = response.data;
                    if (this.fastSetsProducts.length) {
                        this.isCheaperTogether = true;
                        this.addPrice = response.data[0].price;
                        this.fastSetId = response.data[0].id;
                    } else {
                        this.isCheaperTogether = false;
                        this.showProductId(product_id);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        typeLetter() {
            if (event.target.className !== "keyboard__row") {
                let key = event.target.innerHTML || event.target.className;
                if (key === "clear") this.searchValue = this.searchValue.substr(0, this.searchValue.length - 1);
                else if (key === "Очистить") this.searchValue = "";
                else if (key === "space") this.searchValue += " ";
                else this.searchValue += key.toUpperCase();
            }
        },
        clearInput() {
            var self = this;
            self.searchValue = "";
            axios
                .get(api + `categories/`, {
                    headers: {
                        "Accept-Language": localStorage.getItem("lang"),
                    },
                })
                .then((response) => {
                    self.categories = response.data;
                    self.selectedCategoryId = self.categories[0].id;
                    console.log("categories:" + self.categories);
                    axios
                        .get(api + `products/?category=` + self.selectedCategoryId, {
                            headers: {
                                "Accept-Language": localStorage.getItem("lang"),
                            },
                        })
                        .then((response) => {
                            self.products = response.data;
                            console.log(" this.products", self.products);
                        })
                        .catch((e) => {
                            self.errors.cafes = e.message;
                        });
                })
                .catch((e) => {
                    self.errors.cafes = e.message;
                });
        },
        showProductId(product_id) {
            console.log(product_id);
            this.product_id = product_id;
            this.isShowProduct = true;
            axios
                .get(api + `products/${this.product_id}`, {
                    headers: {
                        "Accept-Language": localStorage.getItem("lang"),
                    },
                })
                .then((response) => {
                    this.product = response.data;
                    if (this.product.modifiers.length > 0 && !this.product.modifiers_as_hint) {
                        this.currentTab = this.product.modifiers[0];
                    }
                    this.handleTabClick(this.currentTab);
                })
                .catch((e) => {
                    this.errors.push(e);
                });
            axios
                .get(api + `recommended-products/?product_id=${this.product_id}`, {
                    headers: {
                        "Accept-Language": localStorage.getItem("lang"),
                    },
                })
                .then((response) => {
                    this.recommendedProducts = response.data;
                    console.log(response);
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        showPrice: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.product.price * item.quantity;
                }
            });
            return output;
        },
        getOrderItem: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.id;
                }
            });
            return output;
        },
        changeCategory: function (categoryId) {
            this.selectedCategoryId = categoryId;
            axios
                .get(api + `products/?category=` + this.selectedCategoryId, {
                    headers: {
                        "Accept-Language": localStorage.getItem("lang"),
                    },
                })
                .then((response) => {
                    this.products = response.data;
                })
                .catch((e) => {
                    this.errors.cafes = e.message;
                });
        },
        addToOrder(order_id, product_id, modifiers, modifiers_as_hint, express) {
            let self = this;
            if (modifiers.length === 0 || modifiers_as_hint) {
                console.log("!!!");
                axios
                    .post(api + `order-add/`, {
                        order_id: order_id,
                        product_id: product_id,
                        express: express ? express : false,
                    })
                    .then(function (response) {
                        self.orderObj = response.data;
                        console.log(response.data);
                    });
            } else {
                console.log("???");
                self.$router.push(`/products/`);
            }
        },
        changeOrderItem(order_id, order_item_id, type) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id: order_id,
                    order_item_id: order_item_id,
                    type: type,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        sendOrder: function (index) {
            let self = this;
            self.setProducts[index].products.forEach(function (item) {
                console.log(item);
                self.addToOrder(self.orderObj.id, item.id, [], [], true);
            });
            self.inCart.push(index);
        },
        changeProductInSet(setIndex, productIndex) {
            let self = this;
            let neededProduct = {};
            axios
                .get(api + `products/?category=` + self.setProducts[setIndex].products[productIndex].category.id, {
                    headers: {
                        "Accept-Language": localStorage.getItem("lang"),
                    },
                })
                .then((response) => {
                    if (response.data.length > 1) {
                        console.log(response.data, response.data.length, neededProduct);
                        let runWhile = true;
                        while (runWhile) {
                            let item = response.data[Math.floor(Math.random() * response.data.length)];
                            if (item.id != self.setProducts[setIndex].products[productIndex].id) {
                                neededProduct = item;
                                break;
                            }
                        }
                        self.setProducts[setIndex].products[productIndex] = neededProduct;
                        let newPrice = 0;
                        self.setProducts[setIndex].products.forEach(function (item) {
                            newPrice += item.price;
                        });
                        self.setProducts[setIndex].price = newPrice;
                    }
                })
                .catch((e) => {
                    this.errors.cafes = e.message;
                });
            console.log(setIndex, productIndex);
            console.log(self.setProducts[setIndex]);
            console.log(self.setProducts[setIndex].products[productIndex]);
        },
        loadSets() {
            let self = this;
            self.load = "Идет загрузка";
            axios
                .get(api + `express-sets/`)
                .then((response) => {
                    response.data.forEach(function (item) {
                        self.setProducts.push(item);
                        self.load = "Загрузить еще...";
                    });
                    self.setsCount += response.data.length;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        handleTabClick(tab) {
            this.currentTab = {
                ...tab,
                product: this.orderObj.items.find((item) => item.selected_modifier === tab.id),
            };
            this.currentTab.price = this.product.price * this.currentTab.quantity;
        },
        modifierChange(mod_id, order_id, type, product_id, order_item_id) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id,
                    product_id,
                    mod_id,
                    type,
                    order_item_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                    self.handleTabClick(self.currentTab);
                });
        },
        modifierAdd(mod_id, order_id, type, product_id, order_item_id) {
            let self = this;
            axios
                .post(api + `order-add/`, {
                    order_id,
                    product_id,
                    mod_id,
                    type,
                    order_item_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                    self.handleTabClick(self.currentTab);
                });
        },
        setPrice(price, id) {
            this.addPrice = price;
            this.fastSetId = id;
        },
        reloadPage() {
            window.location.reload();
        },
        orderFastSetAdd(order_id, fast_set_id, express) {
            let self = this;
            axios
                .post(api + `order-fast-set-add/`, {
                    order_id: this.orderId,
                    fast_set_id: this.fastSetId,
                    express: express ? express : false,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        timerWaiting() {
            this.timerInt = setInterval(() => {
                if (this.sec > this.countSec) {
                    this.sec = 0;
                    this.isWaiting = true;
                }
                if (this.isWaiting && this.sec >= this.countSec) {
                    this.$router.push("/");
                }
                console.log("Past seconds: " + this.sec++);
            }, 1000);
        },
    },
    watch: {
        searchValue: function (newVal, oldVal) {
            // watch it
            if (newVal.length >= 3) {
                this.selectedCategoryId = 0;
                axios
                    .get(api + `products/?search=` + this.searchValue, {
                        headers: {
                            "Accept-Language": localStorage.getItem("lang"),
                        },
                    })
                    .then((response) => {
                        this.products = response.data;
                        console.log(" this.products", self.products);
                    })
                    .catch((e) => {
                        this.errors.cafes = e.message;
                    });
            }
            console.log(`Search was ${oldVal} now - ${newVal}`);
        },
    },
    created() {
        let self = this;
        axios.get(api + `orders/` + self.orderId + "/").then(function (response) {
            self.orderObj = response.data;
            self.handleTabClick(self.currentTab);
        });
    },
    mounted() {
        //this.timerWaiting();
        if (!this.orderId) {
            this.$router.push("/");
        }
        let self = this;
        axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
            self.orderObj = response.data;
        });
        axios.get(api).then(function (response) {
            self.excludeСategories = response.data.kiosk_exclude_categories;

            if (self.excludeСategories) {
                self.selectedCategoryTitle = "Позиции меню";

                axios
                    .get(api + `products/`, {
                        headers: {
                            "Accept-Language": localStorage.getItem("lang"),
                        },
                    })
                    .then((response) => {
                        console.log("yeeeh");
                        self.products = response.data;
                        console.log("this.products", self.products);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else {
                axios
                    .get(api + `categories/`, {
                        headers: {
                            "Accept-Language": localStorage.getItem("lang"),
                        },
                    })
                    .then((response) => {
                        self.categories = response.data;
                        console.log("self.categories");
                        console.log(self.categories);
                        self.selectedCategoryId = self.$route.query.category
                            ? Number(self.$route.query.category)
                            : self.categories[0].id;
                        axios
                            .get(api + `products/?category=` + self.selectedCategoryId, {
                                headers: {
                                    "Accept-Language": localStorage.getItem("lang"),
                                },
                            })
                            .then((response) => {
                                self.products = response.data;
                                console.log(" this.products", self.products);
                            })
                            .catch((e) => {
                                self.errors.cafes = e.message;
                            });
                    })
                    .catch((e) => {
                        self.errors.cafes = e.message;
                    });
            }
        });
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];

        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "it-park";
        }

        self.loadSets();

        axios
            .get(api + `fast-sets/?product=2`)
            .then((response) => {
                this.fastSetsProducts = response.data;
                console.log(this.fastSetsProducts[1]);
            })
            .catch((error) => {
                console.log(error);
            });

        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/`)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    unmounted() {
        clearInterval(this.timerInt);
    },
    computed: {
        totalCountCart() {
            if (!this.orderObj.items) return [];
            return this.orderObj.items.reduce((acc, item) => acc + item.quantity, 0);
        },
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/products";
@media (max-width: $ms3) {
    .footer {
        &__title,
        .total-sum__price {
            img {
                transform: scale(0.5);
            }
        }
    }
}
.cheaper-together {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(55, 55, 55, 0.25);
    z-index: 99999;
    &__popup {
        background-color: #fff;
        width: 100%;
        max-width: 688px;
        display: flex;
        flex-direction: column;
        border-radius: 30px;
        padding: 30px;
        overflow: hidden;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 38.19px;
        color: #000;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__btn {
        width: 49%;
        height: 64px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
        color: #fff;
        font-family: "Nunito Sans";
        margin-top: 30px;
    }
    .products {
        overflow-x: hidden;
        overflow-y: auto;
        height: 60vh;
        padding: 1rem;
        margin-top: 0;
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
            border-radius: 5px;
            margin-top: 2rem;
        }
        &::-webkit-scrollbar-thumb {
            background: #28735f;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .product {
        border-radius: 20px;
        box-shadow: 1px 3px 25px 0px rgb(0, 45, 29, 0.1);
        display: flex;
        overflow: hidden;
        margin-top: 10px;
        cursor: pointer;
        user-select: none;
        &:has(input[type="radio"]:checked) {
            box-shadow: 0 0 0 2px #28735f;
        }
        &__container {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__img {
            min-width: 176px;
            max-width: 176px;
            height: 176px;
            overflow: hidden;
            padding: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__body {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 1 auto;
            padding: 25px;
        }

        &__title {
            font-size: 18px;
            line-height: 24px;
            color: #000;
        }

        &__desc {
            font-size: 14px;
            line-height: 19.1px;
            color: rgb(55, 55, 55, 0.5);
            white-space: pre-line;
        }

        &__price {
            font-weight: 700;
            font-size: 20px;
            line-height: 27.28px;
            display: flex;
        }

        &__bottom {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            input[type="radio"] {
                display: none;
            }
            input[type="radio"]:checked + .product__check:before {
                background: #317763;
            }
        }

        &__check {
            width: 37px;
            height: 37px;
            border-radius: 50%;
            border: 1px solid #e2e2e2;
            position: relative;
            &::before {
                content: "";
                width: 25px;
                height: 25px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: none;
                border-radius: 50%;
                margin: auto;
            }
        }
    }
    .current {
        color: #000;
    }
    .amount {
        color: #bebebe;
        position: relative;
        overflow: hidden;
        margin-left: 10px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 100%;
            height: 1px;
            background: #d66950;
            transform: rotate(-30deg);
        }
    }
    .cancel {
        background: #d66c56;
        &:hover,
        &:focus {
            background: #a95443;
            transition: 0.2s ease 0.2s;
        }
    }
    .done {
        background: #28735f;
        &:hover,
        &:focus {
            background: #1c5042;
            transition: 0.2s ease 0.2s;
        }
    }
    .btn-c {
        box-shadow: 1px 3px 25px 0px rgb(0, 45, 29, 0.1);
        width: 48px;
        height: 48px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 767px) {
    .footer {
        height: auto;
        padding: 1rem 0;
    }
}
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.status {
    padding: 46px 45px 26px;
    @media (max-width: $ms4) {
        padding: 20px 20px 0 20px;
    }
}
.orders {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 0px 0px 24px 0px;
    @media (max-width: $ms2) {
        justify-content: center;
    }
    @media (max-width: $ms3) {
        margin: 0 auto;
        padding: 0px 0px 24px 0px;
    }
}
.block-counter {
    display: flex;
    align-items: center;
    .price {
        margin-right: 17px;
        font-weight: 700;
        @include adaptive-font(25, 14, 0);
        @include adaptive-value("line-height", 26, 15, 0);
    }
}
.footer__orders {
    .counter {
        width: 180px;
        box-shadow: none;
        &__counts {
            font-weight: 700;
            @include adaptive-font(20, 14, 0);
            @include adaptive-value("line-height", 21, 15, 0);
        }
    }
}
</style>
