<template>
    <div class="app">
        <!-- <video width="100%" height="100%" muted autoplay loop v-if="isVideo" @click="touchDisplay">
            <source :src="require(`@/assets/video/${video}`)" type="video/mp4" codecs="mp4" />
        </video> -->
        <swiper
            class="blocks"
            :modules="[Autoplay, EffectFade]"
            :autoplay="{ delay: 8000 }"
            effect="fade"
            :slides-per-view="1"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            @click="touchDisplay"
        >
            <swiper-slide v-for="(image, index) in images" :key="index">
                <img :src="require(`@/assets/img/main/${image.img}`)" :alt="image.img" />
            </swiper-slide>
        </swiper>
        <t-block-empty />
        <div class="touch">Коснись, чтобы начать</div>
        <router-link to="/full-display" class="f11"></router-link>
    </div>
</template>

<script>
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default {
    name: "t-main",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            EffectFade,
            Autoplay,
        };
    },
    data() {
        return {
            video: "movie.mp4",
            images: [
                {
                    img: "4.11-6.11.jpg",
                },
                {
                    img: "team.png",
                },
                {
                    img: "yandex.png",
                },
                {
                    img: "minus20.jpg",
                },
                {
                    img: "t-coins.jpg",
                },
                {
                    img: "work1.jpg",
                },
                {
                    img: "work3.jpg",
                },
            ],
            effect: "fade",
            grabCursor: true,
            loop: true,
            centeredSlides: true,
            slidesPerView: "auto",
            // autoplay: {
            //     delay: 8000,
            //     disableOnInteraction: false,
            // },
            now: new Date(),
            //isVideo: false,
        };
    },
    computed: {
        currentDate() {
            return this.now.getDate() + "." + this.now.getMonth() + 1 + "." + this.now.getFullYear();
        },
    },
    methods: {
        touchDisplay() {
            this.$router.push("/welcome");
        },
    },
    mounted() {
        localStorage.removeItem("token");
        localStorage.removeItem("tel");
        localStorage.removeItem("full_price");
        localStorage.removeItem("message");
        localStorage.removeItem("write_ticoins");
        localStorage.removeItem("lang");
        localStorage.removeItem("orderId");
        localStorage.removeItem("totalSum");
        localStorage.removeItem("promocode");
        let i;
        for (i = 0; i < 100; i++) {
            window.clearInterval(i);
        }
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "mega";
        }
        console.log(this.cafe_slug);

        if (this.cafe_slug === "it-park") {
            this.images.splice(1);
            //this.isVideo = false;
        } else if (this.cafe_slug === "olymp") {
            this.images.splice(0, 1);
        } else {
            this.images.splice(0, 2);
        }

        // if (this.cafe_slug === "kreml") {
        //     this.video = "movie2.mp4";
        // }
        console.log(this.images);
        // if (this.isVideo === true) {
        //     let timer = 14;
        //     this.cafe_slug === "kreml" ? (timer = 8) : (timer = 14);
        //     let interval = setInterval(() => {
        //         if (timer === 0) {
        //             clearInterval(interval);
        //             this.isVideo = false;
        //         } else {
        //             timer--;
        //             console.log(timer);
        //         }
        //     }, 1000);
        // }
        if (this.cafe_slug === "moscow") {
            this.images.unshift({
                img: "solyanka.png",
            });
        }
        if (this.cafe_slug === "pushkina") {
            this.images.unshift({
                img: "solyanka_kiosk.png",
            });
        }
        
        this.images.unshift(
            {
                img: "ak_bars.jpg",
            },
            {
                img: "yandex_delivery.jpg",
            }
        );
        if (this.cafe_slug === "mega") {
            this.images.unshift({
                img: "garri_potter.png",
            });
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.app {
    position: relative;

    .f11 {
        position: absolute;
        bottom: 0;
        right: 0;
        background: none;
        z-index: 999;
        width: 100px;
        height: 30px;
    }
    .touch {
        width: 100%;
        height: 320px;
        background-color: #fff;
        box-shadow: 1px 3px 25px rgba(0, 45, 29, 0.1);
        font-weight: 700;
        font-size: 48px;
        line-height: 65.47px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .block-empty {
        height: calc(100vh - 320px);
    }
    @media (max-width: 1080px) {
        height: 177vw;
    }
    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper {
        width: 100%;
        position: relative;
        @media (max-width: $ms3) {
            height: 100vh;
        }
    }
    .footer {
        position: absolute;
        bottom: 0;
    }
}
</style>
