<template>
    <t-header />
    <div class="catalog-sets pt">
        <div class="catalog-sets__container _container">
            <t-btn-arrow @click="$router.go(-1)" />
            <div class="catalog-sets__blocks">
                <div class="catalog-sets__block" v-for="(i, index) in countProducts" :key="index">
                    <div class="catalog-sets__dishes">
                        <div
                            class="catalog-sets__dish"
                            v-for="product in products[index].products"
                            :key="product.id"
                            :style="
                                products[index].products.length <= 3
                                    ? `margin-left: 30px; margin-right: 30px`
                                    : `margin-left: 10px; margin-right: 10px`
                            "
                        >
                            <div class="catalog-sets__img">
                                <img :src="product.image" :alt="product.real_name" />
                            </div>
                            <div class="catalog-sets__body">
                                <h4>
                                    {{
                                        product.real_name.length > 30
                                            ? product.real_name.substr(0, 30) + "..."
                                            : product.real_name
                                    }}
                                </h4>
                                <p>
                                    {{
                                        product.description.length > 55
                                            ? product.description.replace(/<\/?[^>]+(>|$)/g, "").substr(0, 55) + "..."
                                            : product.description.replace(/<\/?[^>]+(>|$)/g, "")
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="catalog-sets__to-pay" v-if="inCart.indexOf(index) === -1">
                        <button class="catalog-sets__btn" @click="sendOrder(index)">
                            Добавить за {{ products[index].price }} Р
                        </button>
                    </div>
                    <div class="catalog-sets__to-pay" v-if="inCart.indexOf(index) !== -1">
                        <button class="catalog-sets__btn pressOn" @click="this.$router.push('/cart/')">
                            Перейти к оплате
                        </button>
                    </div>
                </div>
                <button class="catalog-sets__load" @click="loadSets()">
                    {{ load }}
                </button>
            </div>
        </div>
    </div>
    <t-orders-hall :orderObj="orderObj" />
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";
export default {
    name: "catalog-sets",
    data() {
        return {
            orderId: localStorage.getItem("orderId"),
            orderObj: {},
            load: `Загрузить ещё...`,
            products: [],
            countProducts: 0,
            inCart: [],
        };
    },
    methods: {
        addToOrder(order_id, product_id) {
            let self = this;
            axios
                .post(api + `order-add/`, {
                    order_id: order_id,
                    product_id: product_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        sendOrder: function (index) {
            let self = this;
            self.products[index].products.forEach(function (item) {
                self.addToOrder(self.orderObj.id, item.id);
            });
            self.inCart.push(index);
        },
        loadSets() {
            let self = this;
            self.load = "Идет загрузка";
            axios
                .get(api + `express-sets/`)
                .then((response) => {
                    response.data.forEach(function (item) {
                        self.products.push(item);
                        self.load = "Загрузить еще...";
                    });
                    self.countProducts += response.data.length;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        if (!this.orderId) {
            this.$router.push("/");
        }
        let self = this;
        axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
            self.orderObj = response.data;
        });

        self.loadSets();
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.catalog-sets {
    @include adaptive-value("padding-bottom", 100, 20, 0);
    &__blocks {
        height: 62vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 1rem;
        margin-top: 3rem;
        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
            border-radius: 10px;
            margin-top: 28px;
        }
        &::-webkit-scrollbar {
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background: #28735f;
            border-radius: 10px;
        }
    }
    &__block {
        background-color: #28735f;
        border-radius: 20px;
        overflow: hidden;
        padding: 1.5rem;
        &:not(:first-child) {
            margin: 3rem auto 0;
        }
    }
    &__dishes {
        display: flex;
        justify-content: center;
        margin: 0 -10px;
        @media (max-width: $ms3) {
            flex-wrap: wrap;
        }
    }
    &__btn {
        font-family: "Nunito";
        width: 100%;
        height: 57px;
        border-radius: 15px;
        background-color: #d36d51;
        font-size: 20px;
        font-weight: 800;
        line-height: 27.28px;
        text-align: center;
        color: #fffff7;
        &:hover,
        &:focus {
            background-color: #d34e2a;
            transition: 0.2s ease 0.2s;
        }
    }
    &__to-pay {
        margin-top: 1.5rem;
        @media (max-width: $ms3) {
            margin-top: 0;
        }
    }
    &__load {
        margin: 3rem auto 0;
        background-color: rgba(40, 115, 95, 0.25);
        border-radius: 20px;
        width: 100%;
        height: 90px;
        font-family: "Nunito";
        font-size: 30px;
        font-weight: 800;
        line-height: 40.92px;
        text-align: center;
        color: #fffff7;
        &:hover,
        &:focus {
            background-color: rgba(40, 115, 95, 1);
            transition: 0.2s ease 0.2s;
        }
    }
    &__dish {
        flex: 0 0 23%;
        height: auto;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @media (max-width: $ms3) {
            flex: 0 0 45%;
            margin-left: 10px !important;
            margin-right: 10px !important;
            margin-bottom: 20px;
        }
        @media (max-width: $ms4) {
            margin-left: 0 !important;
            margin-right: 0 !important;
            flex: 1 1 auto;
        }
    }
    &__img {
        width: 100%;
        height: 182px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__body {
        background-color: #fffff7;
        font-family: "Fregat";
        font-weight: 400;
        color: #0e0e0e;
        padding: 1rem;
        flex: 1 1 auto;
        h4 {
            font-size: 18px;
            line-height: 20.6px;
        }
        p {
            font-size: 14px;
            line-height: 18.54px;
            margin-top: 10px;
            word-break: break-word;
        }
    }
    .pressOn {
        background-color: #d34e2a;
    }
}
//.catalog-sets ~ .footer .footer__header {
//  border-radius: 0;
//}
//.catalog-sets ~ .footer .footer__buttons {
//  display: none;
//}
</style>
