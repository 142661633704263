<template>
    <div class="backdrop" @click="$emit('close')"></div>
    <div class="write-ticoins" @click.stop>
        <div class="write-ticoins__container">
            <div class="write-ticoins__close" @click="$emit('close')">
                <img :src="require(`@/assets/img/${close_img}`)" alt="close" />
            </div>
            <div class="write-ticoins__head">
                <h2 class="write-ticoins__title">
                    {{ $t("write_ticoins.title") }}
                    <span
                        >{{ bonuses }}
                        <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.998 12.4566C12.324 12.4566 10.1577 14.5464 10.1577 17.1215C10.2592 20.0507 11.8733 23.0093 14.998 25.9992C13.0956 26.0207 11.5241 25.5922 9.70087 25.0032C3.95506 23.1483 0 17.6635 0 11.4763V1.83738C4.51137 1.83738 8.02991 4.32049 8.02991 10.3081C8.02991 4.46724 10.7424 2.44006 15 0C19.2556 2.4381 21.9701 4.46724 21.9701 10.3081C21.9701 4.32049 25.4886 1.83738 30 1.83738V11.4763C30 17.6615 26.0449 23.1483 20.2991 25.0032C18.4759 25.5922 16.9044 26.0207 15.002 25.9992C18.1267 23.0093 19.7408 20.0507 19.8423 17.1215C19.8423 14.5445 17.676 12.4566 15.002 12.4566"
                                fill="#28735F"
                            />
                        </svg>
                    </span>
                </h2>
            </div>
            <div class="write-ticoins__input">
                <input
                    type="text"
                    name="write"
                    :placeholder="$t('write_ticoins.placeholder')"
                    v-model.trim="writeTicoins"
                    @keyup="keyNumber"
                />
            </div>
            <div class="error">{{ error ? $t(`write_ticoins.error["${error}"]`) : "" }}</div>
            <div class="keyboard">
                <div class="keys">
                    <div class="flex" v-for="(row, i) in 3" :key="i">
                        <div class="key" v-for="(numb, index) in 3" :key="index" @click="pressKey(numb + i * 3)">
                            {{ numb + i * 3 }}
                        </div>
                    </div>
                    <div class="flex">
                        <div class="key bcg btn-clear" @click="(writeTicoins = ''), (error = '')">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.73828 1.89062L19.9566 20.109M20.2603 1.89062L2.04192 20.109"
                                    stroke="#28735F"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                        <div class="key" @click="pressKeyZero()">0</div>
                        <div class="key bcg btn-delete" @click="backSpace()">
                            <svg
                                width="28"
                                height="20"
                                viewBox="0 0 28 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.34693 1.78719C9.53398 1.60315 9.78588 1.5 10.0483 1.5H25.5742C26.1264 1.5 26.5742 1.94771 26.5742 2.5V17.5C26.5742 18.0523 26.1264 18.5 25.5742 18.5H10.0483C9.78588 18.5 9.53398 18.3969 9.34693 18.2128L1.72443 10.7128C1.32623 10.321 1.32623 9.67898 1.72443 9.28719L9.34693 1.78719Z"
                                    stroke="#28735F"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="write-ticoins__bottom-text">{{ bottom_text }}</div> -->
            <div class="buttons">
                <button class="btn btn-apply" @click="applyPromoCode()">{{ $t("write_ticoins.btn_apply") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: ["bonuses", "orderObj"],
    data() {
        return {
            placeholder: "Сколько списать?",
            close_img: "close.png",
            btn_exit: "Выйти из профиля",
            btn_apply: "Применить",
            bottom_text: "После оформления заказа выход из профиля будет автоматический",
            title: "На вашем счете",
            writeTicoins: "",
            token: localStorage.getItem("token"),
            orderId: localStorage.getItem("orderId"),
            shop_id: localStorage.getItem("shop_id"),
            error: "",
        };
    },
    methods: {
        pressKey(numb) {
            this.writeTicoins += numb;
            this.error = "";
        },
        pressKeyZero() {
            if (!this.writeTicoins) {
                this.error = "Списание не меньше 1";
            } else {
                this.writeTicoins += 0;
                this.error = "";
            }
        },
        backSpace() {
            this.writeTicoins = this.writeTicoins.substr(0, this.writeTicoins.length - 1);
            this.error = "";
        },
        keyNumber(v) {
            this.writeTicoins = v.target.value.replace(/[^0-9]/g, "");
        },
        applyPromoCode() {
            if (!this.writeTicoins) {
                this.error = "Введите списание Т-коинов";
            } else if (Number(this.writeTicoins) === Number(this.orderObj.full_price)) {
                this.error = "Кол-во Т-коинов не должно быть равно сумме заказа";
            } else if (Number(this.writeTicoins) > Number(this.orderObj.full_price)) {
                this.error = "Кол-во Т-коинов не должно быть больше суммы заказа";
            } else {
                this.writeTicoinsApi();
            }
        },
        writeTicoinsApi() {
            axios
                .post(
                    `https://tcode.online/api/${this.cafe_slug}/bonuses/set/`,
                    {
                        order_id: this.orderId,
                        bonuses: this.writeTicoins,
                    },
                    {
                        headers: {
                            Authorization: "Token " + this.token,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    this.$emit("close");
                    localStorage.setItem("write_ticoins", response.data.success);
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    this.error = error.response.data.error;
                });
        },
    },
    mounted() {
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "pushkina";
        }
    },
};
</script>

<style lang="scss" scoped>
.backdrop {
    background: rgb(55, 55, 55, 0.25);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
}
.write-ticoins {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    background: #fff;
    width: 100%;
    max-width: 896px;
    height: fit-content;
    margin: auto;
    border-radius: 30px;
    box-shadow: 1px 3px 25px 0 rgb(0, 45, 29, 0.1);
    .error {
        text-align: center;
        margin-top: 1rem;
        color: red;
    }
    &__container {
        position: relative;
        padding: 20px;
    }

    &__close {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 36px;
        line-height: 49.1px;
        color: #000;
        text-align: center;
        margin-top: 10px;
        span {
            color: #28735f;
        }
    }

    &__input {
        max-width: 512px;
        height: 80px;
        border: 2px solid #f4f4f4;
        border-radius: 15px;
        overflow: hidden;
        margin: 35px auto 0;
        input {
            font-weight: 400;
            font-size: 32px;
            line-height: 43.65px;
            color: #000;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 0 20px;
            &::placeholder {
                color: #bebebe;
            }
        }
    }

    &__bottom-text {
        font-size: 15px;
        line-height: 24px;
        color: #bebebe;
        text-align: center;
        margin-top: 50px;
    }
    .keyboard {
        margin-top: 40px;
    }
    .keys {
        display: flex;
        flex-direction: column;
        align-items: center;
        .flex {
            display: flex;
        }
    }
    .key {
        width: 79px;
        height: 79px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 1px 3px 25px 0 rgb(0, 45, 29, 0.1);
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
        color: #28735f;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 10px 15px;
        &:hover,
        &:focus {
            background: #eee;
            transition: 0.2s ease 0.2s;
        }
    }
    .bcg {
        background: #e4f9f3;
    }
    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }
    .btn {
        width: 49%;
        height: 80px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 22px;
        line-height: 30.01px;
        display: flex;
        justify-content: center;
    }
    .btn-exit {
        background: none;
        color: #28735f;
        border: 3px solid #28735f;
        &:hover,
        &:focus {
            background: #28735f;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
    }
    .btn-apply {
        background: #28735f;
        color: #fff;
        &:hover,
        &:focus {
            background: #3a977e;
            transition: 0.2s ease 0.2s;
        }
    }
}
</style>
