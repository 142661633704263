import ru from "./ru.json";
import en from "./en.json";
import tt from "./tt.json";

export const defaultLocale = "ru";

export const languages = {
    ru,
    en,
    tt,
};
