<template>
    <div class="auth" v-if="isAuth">
        <div class="auth__container" @click.stop>
            <div class="auth__img-a img-top-l" v-if="authSign">
                <img :src="require(`@/assets/img/coins/` + img_top_l)" :alt="img_top_l" />
            </div>
            <div class="auth__img-a img-top-r" v-if="authSign">
                <img :src="require(`@/assets/img/coins/` + img_top_r)" :alt="img_top_r" />
            </div>
            <div class="auth__img-a img-bottom-r" v-if="authSign">
                <img :src="require(`@/assets/img/coins/` + img_bottom_r)" :alt="img_bottom_r" />
            </div>
            <div class="auth__img-a img-bottom-l" v-if="authSign">
                <img :src="require(`@/assets/img/coins/` + img_bottom_l)" :alt="img_bottom_l" />
            </div>
            <div class="auth__body">
                <div class="auth__close" @click="$emit('closeAuth')">
                    <img :src="require(`@/assets/img/` + close)" :alt="close" />
                </div>
                <div class="auth-sign">
                    <h2 class="auth-sign__title">
                        {{ $t(`popup.auth.title["${authTitle}"]`) }}
                        <!-- {{ authTitle }} -->
                    </h2>
                    <div class="auth-sign__bonuses" v-if="authSign">
                        <span>{{ Math.round((orderObj.full_price / 100) * 7) }}</span>
                        <svg width="89" height="83" viewBox="0 0 89 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M44.494 39.7654C36.5613 39.7654 30.1345 46.4366 30.1345 54.6571C30.4356 64.0081 35.2242 73.4528 44.494 82.9975C38.8502 83.0662 34.1881 81.6982 28.7792 79.818C11.7334 73.8963 0 56.3873 0 36.6358V5.86548C13.3837 5.86548 23.8221 13.7923 23.8221 32.9067C23.8221 14.2608 31.8692 7.78941 44.5 0C57.1248 7.78316 65.1779 14.2608 65.1779 32.9067C65.1779 13.7923 75.6163 5.86548 89 5.86548V36.6358C89 56.3811 77.2667 73.8963 60.2208 79.818C54.8119 81.6982 50.1498 83.0662 44.506 82.9975C53.7759 73.4528 58.5644 64.0081 58.8655 54.6571C58.8655 46.4304 52.4387 39.7654 44.506 39.7654"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <h4 class="auth-sign__subtitle" v-if="authSign">{{ $t("popup.auth.subtitle") }}</h4>
                </div>
                <div class="phone-input" v-if="inputNumbers">
                    <div
                        class="phone-input__phone"
                        :class="{
                            notvalid: phoneValidation.phone,
                            valid: phoneValidation.phone === false,
                        }"
                        :style="phone.length === 0 ? 'color:#bebebe !important' : ''"
                        v-if="phoneInput"
                    >
                        <div class="tel-nums">
                            <div class="seven">+7</div>
                            <div class="tel-input">
                                (
                                <div>
                                    <span v-if="phone[0]">
                                        {{ phone[0] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[1]">
                                        {{ phone[1] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[2]">
                                        {{ phone[2] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                )&nbsp;
                                <div>
                                    <span v-if="phone[3]">
                                        {{ phone[3] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[4]">
                                        {{ phone[4] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[5]">
                                        {{ phone[5] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                -
                                <div>
                                    <span v-if="phone[6]">
                                        {{ phone[6] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[7]">
                                        {{ phone[7] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                -
                                <div>
                                    <span v-if="phone[8]">
                                        {{ phone[8] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                                <div>
                                    <span v-if="phone[9]">
                                        {{ phone[9] }}
                                    </span>
                                    <span v-else>_</span>
                                </div>
                            </div>
                        </div>
                        <input
                            type="hidden"
                            v-mask="'##########'"
                            name="phone"
                            placeholder="(___) ___-__-__"
                            id="phone"
                            v-model.trim="phone"
                            @input="inputPhone"
                        />
                    </div>
                    <div class="error">{{ error }}</div>
                    <div class="phone-input__code" v-if="isCodeInput">
                        <div class="phone-input__post-number">
                            <span>{{ $t("popup.auth.send_code") }}</span>
                            <span>+7 {{ phone }}</span>
                        </div>
                        <div class="circles">
                            <div class="circles__item" v-for="(number, index) in 4" :key="index">
                                <div
                                    class="circles__number"
                                    v-if="code[index]"
                                    :class="{
                                        notvalid: codeValidation.code,
                                        valid: codeValidation.code === false,
                                    }"
                                >
                                    {{ code[index] }}
                                </div>
                                <div class="circles__hidden" v-else></div>
                            </div>
                        </div>
                        <input
                            type="hidden"
                            v-mask="'####'"
                            name="code"
                            placeholder="* * * *"
                            id="code"
                            v-model.trim="code"
                            @input="inputCode"
                        />
                        <div class="error">{{ error }}</div>
                    </div>
                    <div v-if="nameInput">
                        <div class="subtitle">
                            {{ $t("popup.auth.text_input_name") }}
                        </div>
                        <input type="text" placeholder="Имя" v-model="name" class="name-input" />
                    </div>
                    <div class="phone-input__keys phone-rows" v-if="phoneInput" @click="inputPhone">
                        <div class="phone-row">
                            <button class="phone-input__key key">1</button>
                            <button class="phone-input__key key">2</button>
                            <button class="phone-input__key key">3</button>
                        </div>
                        <div class="phone-row">
                            <button class="phone-input__key key">4</button>
                            <button class="phone-input__key key">5</button>
                            <button class="phone-input__key key">6</button>
                        </div>
                        <div class="phone-row">
                            <button class="phone-input__key key">7</button>
                            <button class="phone-input__key key">8</button>
                            <button class="phone-input__key key">9</button>
                        </div>
                        <div class="phone-row">
                            <button class="btn btn-green btn-clear key">
                                <img src="@/assets/img/svg/delete.svg" alt="delete" class="delete" />
                            </button>
                            <button class="phone-input__key key">0</button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                            </button>
                        </div>
                    </div>
                    <div class="phone-input__keys phone-rows" v-if="isCodeInput" @click="inputCode">
                        <div class="phone-row">
                            <button class="phone-input__key key">1</button>
                            <button class="phone-input__key key">2</button>
                            <button class="phone-input__key key">3</button>
                        </div>
                        <div class="phone-row">
                            <button class="phone-input__key key">4</button>
                            <button class="phone-input__key key">5</button>
                            <button class="phone-input__key key">6</button>
                        </div>
                        <div class="phone-row">
                            <button class="phone-input__key key">7</button>
                            <button class="phone-input__key key">8</button>
                            <button class="phone-input__key key">9</button>
                        </div>
                        <div class="phone-row">
                            <button class="btn btn-green btn-clear key">
                                <img src="@/assets/img/svg/delete.svg" alt="delete" class="delete" />
                            </button>
                            <button class="phone-input__key key">0</button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="auth-success" v-if="authSuccess">
                    <h2 class="auth-success__title">{{ $t("popup.auth.success") }}</h2>
                    <div class="auth-success__phone">
                        <p class="auth-success__our-tel">{{ $t("popup.auth.our_phone") }}</p>
                        <div class="auth-success__input-tel">+7 {{ phone }}</div>
                    </div>
                </div>
            </div>
            <div class="auth__bottom">
                <div class="auth__buttons" v-if="authSign">
                    <button class="btn btn-bgn" @click="$emit('closeAuth')">{{ $t("popup.auth.skip") }}</button>
                    <button class="btn btn-green" @click="showPhoneInput()">{{ $t("popup.auth.login") }}</button>
                </div>
                <div class="auth__buttons" v-if="inputNumbers">
                    <template v-if="phoneInput"
                        ><button class="btn btn-bgn" @click="cancelPhoneInput()">{{ $t("popup.auth.back") }}</button>
                        <button class="btn btn-green btnAddPhone" @click="validPhone()">
                            {{ $t("popup.auth.continue") }}
                        </button></template
                    >
                    <template v-if="isCodeInput"
                        ><button class="btn btn-bgn" @click="cancelValidPhone()">{{ $t("popup.auth.back") }}</button
                        ><button class="btn btn-green btnAddPhone" @click="addCode()">
                            {{ $t("popup.auth.continue") }}
                        </button></template
                    >
                    <template v-if="nameInput"
                        ><button class="btn btn-bgn" @click="cancelValidCode()">{{ $t("popup.auth.back") }}</button
                        ><button class="btn btn-green btnAddPhone" @click="validName()">
                            {{ $t("popup.auth.continue") }}
                        </button></template
                    >
                </div>
                <div class="auth__buttons" v-if="finishAuth">
                    <button class="btn btn-green" @click="popupAuthContinue()">{{ $t("popup.auth.continue") }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="keyboard-block popupInputName" v-if="isInputName">
        <div class="keyboard">
            <div class="keyboard__block" @click.stop>
                <div class="keyboard__container _container">
                    <div class="keyboard__body">
                        <div class="keyboard__keys" @click="inputName">
                            <div class="keyboard__row">
                                <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                    {{ number }}
                                </button>
                                <button class="keyboard__key key">0</button>
                                <button class="btn btn-lightgreen btn-long key">
                                    {{ $t("cart.btn_clear") }}
                                </button>
                            </div>
                            <div class="keyboard__row">
                                <button class="keyboard__key key" v-for="letter in letters1" :key="letter.id">
                                    {{ letter.char }}
                                </button>
                                <button class="btn btn-lightgreen btn-long2 key">
                                    <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                                </button>
                            </div>
                            <div class="keyboard__row">
                                <button class="keyboard__key key" v-for="letter in letters2" :key="letter.id">
                                    {{ letter.char }}
                                </button>
                                <template v-if="name.length >= 2">
                                    <button class="btn btn-lightgreen btn-long2 key">
                                        <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn btn-lightgreen btn-long2 key" @click.stop>
                                        <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                                    </button>
                                </template>
                            </div>
                            <div class="keyboard__row">
                                <button class="keyboard__key key" v-for="letter in letters3" :key="letter.id">
                                    {{ letter.char }}
                                </button>
                                <button class="btn btn-lightgreen btn-long key">ABC</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
    name: "t-popup-auth",
    data() {
        return {
            finishAuth: false,
            isInputName: false,
            nameInput: false,
            isAuth: true,
            authSign: true,
            phoneInput: false,
            authSuccess: false,
            isCodeInput: false,
            inputNumbers: false,
            success: "Неверный промокод! Попробуйте ещё раз!",
            phoneValidation: {
                phone: null,
            },
            phone: "",
            promocodeinput: "",
            authTitle: "Вы могли бы получить",
            codeValidation: {
                code: null,
            },
            code: "",
            promocodeValidation: {
                promocode: null,
            },
            letters: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
                { id: 13, char: `ё` },
                { id: 14, char: `ф` },
                { id: 15, char: `ы` },
                { id: 16, char: `в` },
                { id: 17, char: `а` },
                { id: 18, char: `п` },
                { id: 19, char: `р` },
                { id: 20, char: `о` },
                { id: 21, char: `л` },
                { id: 22, char: `д` },
                { id: 23, char: `ж` },
                { id: 24, char: `э` },
                { id: 25, char: `я` },
                { id: 26, char: `ч` },
                { id: 27, char: `с` },
                { id: 28, char: `м` },
                { id: 29, char: `и` },
                { id: 31, char: `т` },
                { id: 32, char: `ь` },
                { id: 33, char: `б` },
                { id: 34, char: `ю` },
                { id: 35, char: `_` },
            ],
            close: "close.png",
            img: `t-coins.png`,
            name: localStorage.getItem("name") ?? "",
            letters1: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
            ],
            letters2: [
                { id: 1, char: `ф` },
                { id: 2, char: `ы` },
                { id: 3, char: `в` },
                { id: 4, char: `а` },
                { id: 5, char: `п` },
                { id: 6, char: `р` },
                { id: 7, char: `о` },
                { id: 8, char: `л` },
                { id: 9, char: `д` },
                { id: 10, char: `ж` },
                { id: 11, char: `э` },
            ],
            letters3: [
                { id: 1, char: `я` },
                { id: 2, char: `ч` },
                { id: 3, char: `с` },
                { id: 4, char: `м` },
                { id: 5, char: `и` },
                { id: 6, char: `т` },
                { id: 7, char: `ь` },
                { id: 8, char: `б` },
                { id: 9, char: `ю` },
                { id: 10, char: `_` },
            ],
            token: localStorage.getItem("token"),
            subtitle: `Войдите в профиль,\n чтобы копить Т-коины`,
            img_top_l: "top-l.png",
            img_top_r: "top-r.png",
            img_bottom_l: "bottom-l.png",
            img_bottom_r: "bottom-r.png",
            error: "",
            project_id: 1,
            orderId: localStorage.getItem("orderId"),
            active: 1,
        };
    },
    props: ["orderObj"],
    methods: {
        showPhoneInput() {
            this.authSign = false;
            this.inputNumbers = true;
            this.phoneInput = true;
            this.authTitle = "Введите номер телефона";
        },
        cancelPhoneInput() {
            this.authSign = true;
            this.inputNumbers = false;
            this.phoneInput = false;
            this.authTitle = "Вы могли бы получить";
        },
        cancelClose() {
            this.isPopupKeyboad = false;
            this.message = "";
        },
        inputPhone() {
            if (event.target.className !== "keyboard__keys" && event.target.className !== "phone-row") {
                let number = event.target.innerHTML || event.target.className;
                if (number === "clear") this.phone = this.phone.substr(0, this.phone.length - 1);
                else if (number === "delete") {
                    this.phone = "";
                    this.error = "";
                } else {
                    this.phone.length > 9
                        ? (this.phone = this.phone.substr(0, this.phone.length))
                        : (this.phone += number);
                }
            }
            this.phoneValidation.phone = !/^[\d]{10}$/.test(this.phone) ? true : false;
        },
        inputCode() {
            if (event.target.className !== "keyboard__keys" && event.target.className !== "phone-row") {
                let num = event.target.innerHTML || event.target.className;
                if (num === "clear") this.code = this.code.substr(0, this.code.length - 1);
                else if (num === "delete") {
                    this.code = "";
                    this.error = "";
                } else {
                    this.code.length > 3 ? (this.code = this.code.substr(0, this.code.length)) : (this.code += num);
                }
            }
            this.codeValidation.code = !/^[\d]{4}$/.test(this.code) ? true : false;
        },
        inputName() {
            if (event.target.className !== "keyboard__keys" && event.target.className !== "keyboard__row") {
                let key = event.target.innerHTML || event.target.className;
                if (key === "clear") this.name = this.name.substr(0, this.name.length - 1);
                else if (key === "Очистить") this.name = "";
                else if (key === "space") this.name += " ";
                else this.name += key.toUpperCase();
            }
        },
        addPhone() {
            axios
                .post(`https://tcode.online/api/auth/phone/`, {
                    phone: `+7${this.phone}`,
                    project: this.project_id,
                })
                .then((response) => {
                    console.log(response.data);
                    this.successValidPhone();
                })
                .catch((error) => {
                    console.log(error);
                    this.cancelValidPhone();
                    this.error = error.response.data.error;
                });
        },
        addCode() {
            if (!this.codeValidation.code && this.code.length === 4) {
                axios
                    .post(`https://tcode.online/api/auth/verification/`, {
                        phone: `+7${this.phone}`,
                        project: this.project_id,
                        verification_code: this.code,
                    })
                    .then((response) => {
                        console.log(response.data);
                        localStorage.setItem("token", response.data.token);
                        this.isCodeInput = false;
                        this.isInputName = true;
                        this.nameInput = true;
                        this.authTitle = "Введите имя";
                        this.error = "";
                        this.addPhoneOrder(response.data.token);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isCodeInput = true;
                        this.isInputName = false;
                        this.nameInput = false;
                        this.error = "Вы неверно ввели код";
                    });
            }
        },
        addPhoneOrder(token) {
            axios
                .post(
                    `https://tcode.online/api/${this.cafe_slug}/phone-add/`,
                    {
                        order_id: this.orderId,
                        phone: `+7${this.phone}`,
                    },
                    {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        successValidPhone() {
            this.phoneInput = false;
            this.isCodeInput = true;
            this.authTitle = "Введите код";
            this.error = "";
        },
        cancelValidPhone() {
            this.phoneInput = true;
            this.isCodeInput = false;
            this.authTitle = "Введите номер телефона";
        },
        cancelValidCode() {
            this.isCodeInput = true;
            this.nameInput = false;
            this.authTitle = "Введите код";
            this.isInputName = false;
        },
        validPhone() {
            if (!this.phoneValidation.phone && this.phone.length > 0) {
                this.addPhone();
                let self = this;
                axios.post(api + "iiko-name-by-phone/", { phone: "7" + this.phone }).then(function (response) {
                    if (response.data.name) {
                        self.name = response.data.name;
                    }
                });
            }
        },
        successValidName() {
            this.nameInput = false;
            this.authTitle = "Вы успешно авторизованы!";
            this.isInputName = false;
            this.finishAuth = true;
            localStorage.setItem("name", this.name);
            localStorage.setItem("tel", this.phone);
        },
        cancelValidName() {
            this.nameInput = true;
            this.authTitle = "Как вас зовут?";
            this.isInputName = true;
            this.finishAuth = false;
        },
        validName() {
            this.name.length >= 2 ? this.successValidName() : this.cancelValidName();
        },
        popupAuthContinue() {
            this.$emit("closeAuth");
            window.location.reload();
        },
    },
    mounted() {
        //this.token ? (this.isAuth = false) : (this.isAuth = true);
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "pushkina";
        }
        console.log(this.cafe_slug);
    },
};
</script>
<style lang="scss" scoped>
.phone-input__code {
    margin-top: 2rem;
}
.phone-input__post-number {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.error {
    text-align: center;
    color: red;
    font-size: 16px;
}
</style>
