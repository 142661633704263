<template>
    <div
        @mousedown="sec = 0"
        @mousemove="sec = 0"
        @mouseup="sec = 0"
        @mouseenter="sec = 0"
        @mouseleave="sec = 0"
        @touchstart="sec = 0"
        @touchmove="sec = 0"
        @touchcancel="sec = 0"
        @touchend="sec = 0"
        @scrollstart="sec = 0"
        @scroll="sec = 0"
        @scrollend="sec = 0"
    >
        <div class="cart-page" :class="(isPopupAuth && orderObj.items.length) || isPopupPromocode ? `blur` : false">
            <t-back-to-page />
            <div class="cart-page__body">
                <div class="order-detailed">
                    <div class="order-detailed__container">
                        <div v-if="orderObj && orderObj.items.length <= 0">
                            <br />
                            <h2 class="order-detailed__title">
                                <br />{{ $t("cart.cart_empty") }}.<br /><br />{{ $t("cart.cart_empty2") }}<br /><a
                                    href="/products/"
                                    class="btn btn-beige to-catalog"
                                    >{{ $t("cart.to_catalog") }}.</a
                                >
                            </h2>
                        </div>
                        <div class="block-rows" v-else>
                            <div class="_container">
                                <div class="block-orders">
                                    <div class="block-order" v-for="item in orderObj.items" :key="item.id">
                                        <div class="block-order__img">
                                            <img
                                                :src="
                                                    item.product.image
                                                        ? item.product.image
                                                        : require(`@/assets/img/${nophoto}`)
                                                "
                                                :alt="item.product.real_name"
                                            />
                                        </div>
                                        <div class="block-order__body">
                                            <div class="block-order__text">
                                                <h4 class="block-order__title">
                                                    {{ item.product.real_name }}
                                                </h4>
                                                <div class="block-col">
                                                    <div class="block-order__weight">{{ item.product.weight }} г</div>
                                                    <div class="block-order__price">{{ item.product.price }} ₽</div>
                                                </div>
                                            </div>
                                            <div class="block-order__buttons">
                                                <div class="counter">
                                                    <button
                                                        class="counter__minus"
                                                        @click="changeOrderItem(orderObj.id, item.id, 'decrease')"
                                                    >
                                                        -
                                                    </button>
                                                    <span class="counter__counts">{{ item.quantity }}</span>
                                                    <button
                                                        class="counter__plus"
                                                        @click="changeOrderItem(orderObj.id, item.id, 'increase')"
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                                <button
                                                    class="btn btn-remove flex-center"
                                                    @click="changeOrderItem(orderObj.id, item.id, 'delete')"
                                                >
                                                    {{ $t("buttons.remove") }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="count-tools">
                                <div class="count-tools__container">
                                    <div class="count-tools__name">
                                        <img src="@/assets/img/svg/tools.svg" alt="tools" width="19.43" height="24" />
                                        <span>{{$t("cart.chopsticks")}}</span>
                                        <input type="hidden" v-model="chopsticks" />
                                    </div>
                                    <div class="counter">
                                        <button class="counter__minus" @click="decreaseCounts">-</button>
                                        <span class="counter__counts">{{ chopsticks }}</span>
                                        <button class="counter__plus" @click="increaseCounts">+</button>
                                    </div>
                                </div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <t-total-sum :orderObj="orderObj" /> -->
                <!-- <t-footer-order /> -->
                <!--<t-slider  :recommendedProducts="recommendedProducts" />-->
                <div class="slider" :recommendedProducts="recommendedProducts" v-if="orderObj.items.length">
                    <div class="slider__container _container">
                        <h2 class="slider__title">{{ $t("cart.add_to_order") }}</h2>
                        <swiper
                            class="slider__blocks"
                            :modules="modules"
                            :slides-per-view="2"
                            :space-between="28"
                            navigation
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                        >
                            <swiper-slide class="slider__block" v-for="rd in recommendedProducts" :key="rd.id">
                                <div class="slider__img">
                                    <img :src="rd.image ? rd.image : require(`@/assets/img/${nophoto}`)" alt="1" />
                                </div>
                                <div class="slider__body">
                                    <div class="slider__name md">
                                        {{ rd.real_name }}
                                    </div>
                                    <div class="slider__name mm">
                                        {{
                                            rd.real_name.length > 30 ? rd.real_name.substr(0, 30) + "..." : rd.real_name
                                        }}
                                    </div>
                                    <div class="card-dish__change" v-if="orderObj.products_ids.indexOf(rd.id) != -1">
                                        <div class="counter">
                                            <button
                                                class="counter__minus"
                                                @click="changeOrderItem(orderObj.id, getOrderItem(rd.id), 'decrease')"
                                            >
                                                -
                                            </button>
                                            <span class="counter__counts">{{ showPrice(rd.id) }} ₽</span>
                                            <button
                                                class="counter__plus"
                                                @click="changeOrderItem(orderObj.id, getOrderItem(rd.id), 'increase')"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div class="block-flex" v-if="orderObj.products_ids.indexOf(rd.id) == -1">
                                        <div class="price">{{ rd.price }} ₽</div>
                                        <div class="btn-green add-to-cart" @click="addToOrder(orderObj.id, rd.id)">
                                            +
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
            <div class="block-side" v-if="orderObj.items.length">
                <div class="_container">
                    <div class="block-side__container">
                        <div class="block-side__block">
                            <div class="t-coin" :class="{ 'block-profile': token }">
                                <div class="img-coin coin1"><img src="@/assets/img/coin.png" alt="coin" /></div>
                                <div class="img-coin coin2"><img src="@/assets/img/coin.png" alt="coin" /></div>
                                <div class="t-coin__body">
                                    <p class="t-coin__subtite" v-if="token">{{ $t("cart.hello") }}, {{ userName }}!</p>
                                    <h4 class="t-coin__title">
                                        <template v-if="token">
                                            <div class="t-coin__items">
                                                <div class="t-coin__item">
                                                    {{ $t("cart.your_balance") }}
                                                    <span class="balls"
                                                        >{{ bonuses }}
                                                        <img src="../assets/img/svg/flower.svg" alt="flower" width="26"
                                                    /></span>
                                                </div>
                                                <div class="t-coin__item">
                                                    {{ $t("cart.your_balls") }}
                                                    <span class="balls">
                                                        +{{ Math.round((orderObj.full_price / 100) * 7) }}
                                                        <img
                                                            src="../assets/img/svg/flower.svg"
                                                            alt="flower"
                                                            width="26"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>{{ $t("cart.earn_ticoin") }}</template>
                                    </h4>
                                    <p class="t-coin__subtite" v-if="!token">{{ $t("cart.redeem") }}</p>
                                </div>
                                <div class="block-flex">
                                    <button
                                        class="t-coin__btn"
                                        @click="token ? exitProfile() : (isPopupAuth = true)"
                                        :class="{ 'btn-exit': token }"
                                    >
                                        {{ token ? $t("cart.btn_exit_profile") : $t("cart.btn_earn_ticoin") }}
                                    </button>
                                    <div class="text_mark" v-if="token">
                                        {{ $t("cart.text_mark") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="block-side__block">
                            <div class="stroke" v-if="token">
                                <div class="stroke__body" v-if="write_ticoins" @click="cancelWriteTicoins()">
                                    <div class="stroke__title">
                                        {{ $t("cart.write_off") }}
                                        <span class="write_off_value">{{
                                            `-${Number(orderObj.loyalty_bonuses)}`
                                        }}</span>
                                        <svg
                                            width="19"
                                            height="16"
                                            viewBox="0 0 19 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.49871 7.66561C7.80523 7.66561 6.4332 8.95164 6.4332 10.5363C6.4975 12.3389 7.51976 14.1596 9.49871 15.9995C8.29386 16.0128 7.29859 15.7491 6.14388 15.3866C2.50487 14.2451 0 10.8699 0 7.06233V1.1307C2.8572 1.1307 5.08561 2.65876 5.08561 6.34345C5.08561 2.74907 6.80353 1.50157 9.5 0C12.1952 1.50037 13.9144 2.74907 13.9144 6.34345C13.9144 2.65876 16.1428 1.1307 19 1.1307V7.06233C19 10.8686 16.4951 14.2451 12.8561 15.3866C11.7014 15.7491 10.7061 16.0128 9.50129 15.9995C11.4802 14.1596 12.5025 12.3389 12.5668 10.5363C12.5668 8.95044 11.1948 7.66561 9.50129 7.66561"
                                                fill="#D66C56"
                                            />
                                        </svg>
                                    </div>
                                    <div class="stroke__arrow">
                                        <img src="@/assets/img/svg/arrow-input.svg" alt="arrow" />
                                    </div>
                                </div>
                                <div class="stroke__body" v-else @click="isWriteTicoins = true">
                                    <div class="stroke__title">{{ $t("cart.write_ticoins") }}</div>
                                    <div class="stroke__arrow">
                                        <img src="@/assets/img/svg/arrow-input.svg" alt="arrow" />
                                    </div>
                                </div>
                            </div>
                            <div class="stroke">
                                <div class="stroke__body" v-if="promocode.active && promocode.type !== 'Product'">
                                    <div class="stroke__rows">
                                        <div class="stroke__flex">
                                            <div class="stroke__text">{{ $t("cart.promo_code") }}</div>
                                            <div class="stroke__promocode">{{ promocode.code }}</div>
                                        </div>
                                        <div class="stroke__discount">
                                            {{ messagePromo }}
                                            <!-- {{ $t("cart.discount") }}
                                            <span v-if="promocode.type === 'Percent'">
                                                {{ promocode.discount_amount }} %
                                            </span>
                                            <span v-else-if="promocode.type === 'FlexibleSum'">
                                                {{ promocode.discount_amountt }} руб
                                            </span>
                                            <span v-else> {{ amount }} ₽</span> -->
                                        </div>
                                    </div>
                                    <button class="stroke__remove" @click="deletePromo()">
                                        {{ $t("buttons.remove") }}
                                    </button>
                                </div>
                                <div class="stroke__body" v-else @click="applyPromo()">
                                    <div class="stroke__title">{{ $t("cart.enter_promo_code") }}</div>
                                    <div class="stroke__arrow">
                                        <img src="@/assets/img/svg/arrow-input.svg" alt="arrow" />
                                    </div>
                                </div>
                            </div>
                            <div class="block-form">
                                <div class="form_radio_btn">
                                    <input
                                        id="radio-1"
                                        type="radio"
                                        name="radio"
                                        :value="$t('cart.order_type_name1')"
                                        checked
                                    />
                                    <label for="radio-1">{{ $t("cart.order_type_name1") }}</label>
                                </div>
                                <div class="form_radio_btn">
                                    <input
                                        id="radio-2"
                                        type="radio"
                                        name="radio"
                                        :value="$t('cart.order_type_name2')"
                                    />
                                    <label for="radio-2">{{ $t("cart.order_type_name2") }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="block-side__btn" @click="sendOrder()">
                        <div class="total-sum__price">
                            <span>{{ $t("cart.place_order_for") }}</span>
                            <div class="total-sum__value">
                                <div
                                    class="removeOldPrice"
                                    v-if="(promocode.active && promocode.type !== 'Product') || write_ticoins"
                                >
                                    {{ orderObj.products_price }}
                                    ₽
                                </div>
                                <div>
                                    {{ orderObj.full_price }}
                                    ₽
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="success" v-if="isPopupSuccess">
            <p>{{ success }}</p>
            <button class="btn btn-green" @click="isPopupSuccess = false">ОК</button>
        </div>
        <div class="keyboard-block" v-if="isPopupKeyboad" @click="cancelClose()">
            <div class="keyboard" @click.stop>
                <div class="keyboard__block">
                    <div class="keyboard__container _container">
                        <div class="keyboard__body">
                            <input
                                type="text"
                                v-model="message"
                                :placeholder="$t('cart.input_promo')"
                                class="keyboard__title"
                            />
                            <div class="keyboard__keys" @click="typeLetter">
                                <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                    {{ number }}
                                </button>
                                <button class="keyboard__key key">0</button>
                                <button class="btn btn-green key">
                                    <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                                </button>
                                <button class="btn btn-green btn-long key">
                                    {{ $t("cart.btn_clear") }}
                                </button>
                                <button class="keyboard__key key" v-for="letter in letters" :key="letter.id">
                                    {{ letter.char }}
                                </button>
                                <button class="btn btn-green key">
                                    <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="keyboard__buttons">
                    <button class="btn btn-red" @click="cancelClose()">
                        {{ $t("cart.cancel") }}
                    </button>
                    <button
                        class="btnApply"
                        @click="applyPromocode(promocodeInput)"
                        :disabled="promocodeInput ? false : true"
                    >
                        {{ $t("cart.btn_apply") }}
                    </button>
                </div>
            </div>
        </div>
        <PopupAuth
            v-if="isPopupAuth && orderObj.items.length"
            @click="closeAuth"
            @close-auth="closeAuth"
            :orderObj="orderObj"
        />
        <!-- <PopupPromocode
            v-if="isPopupPromocode"
            @is-popup-keyboad="isPopupPromocode = false"
            @apply-promocode="applyPromocode"
            :promocode="promocode"
        /> -->
        <t-you-want-waiting v-if="isWaiting" @close="(isWaiting = false), (sec = 0)" :sec="sec" :countSec="countSec" />
        <div class="keyboard-block popupKeyboad" v-if="isPopupPromocode" @click="isPopupPromocode = false">
            <div class="keyboard" @click.stop>
                <div class="keyboard__block">
                    <div class="keyboard__container _container">
                        <div class="keyboard__body">
                            <template v-if="token">
                                <div class="keyboard__swap"></div>
                                <div class="keyboard__input-promo">
                                    <div class="keyboard__input-block">
                                        <input
                                            type="text"
                                            name="promo"
                                            :placeholder="$t('cart.input_promo')"
                                            v-model="promocodeInput"
                                            @input="promocodeLetter"
                                        />
                                        <span class="error" v-if="isErrorPromo">{{ promocode.error }}</span>
                                    </div>
                                    <button
                                        class="applyPromocode btn-lightgreen"
                                        @click="applyPromocode(promocodeInput)"
                                        :disabled="promocodeInput ? false : true"
                                    >
                                        {{ $t("cart.btn_apply") }}
                                    </button>
                                </div>
                                <div class="keyboard__keys" @click="promocodeLetter">
                                    <div class="keyboard__row">
                                        <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                            {{ number }}
                                        </button>
                                        <button class="keyboard__key key">0</button>
                                        <button class="btn btn-lightgreen btn-long key">
                                            {{ $t("cart.btn_clear") }}
                                        </button>
                                    </div>
                                    <div class="keyboard__row">
                                        <button
                                            class="keyboard__key key"
                                            v-for="letter in lettersLang.letter1"
                                            :key="letter.id"
                                        >
                                            {{ letter.char }}
                                        </button>
                                        <button class="btn btn-lightgreen btn-long2 key">
                                            <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                                        </button>
                                    </div>
                                    <div class="keyboard__row">
                                        <button
                                            class="keyboard__key key"
                                            v-for="letter in lettersLang.letter2"
                                            :key="letter.id"
                                        >
                                            {{ letter.char }}
                                        </button>
                                        <button class="btn btn-lightgreen btn-long2 key">
                                            <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                                        </button>
                                    </div>
                                    <div class="keyboard__row">
                                        <button
                                            class="keyboard__key key"
                                            v-for="letter in lettersLang.letter3"
                                            :key="letter.id"
                                        >
                                            {{ letter.char }}
                                        </button>
                                        <button class="btn btn-lightgreen btn-long key" @click="changeLang">
                                            {{ lang }}
                                        </button>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <h3 class="title-input-message">{{ $t("popup.auth.text_promo_profile") }}</h3>
                                <button class="btn btn-continue" @click="isPopupPromocode = false">
                                    {{ $t("popup.auth.continue") }}
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <write-ticoins :bonuses="bonuses" :orderObj="orderObj" v-if="isWriteTicoins" @close="isWriteTicoins = false" />
    </div>
</template>

<script>
import { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import { api } from "@/api/api";
import PopupAuth from "@/components/PopupAuth.vue";
import WriteTicoins from "@/components/WriteTicoins.vue";
//import PopupPromocode from "@/components/PopupPromocode.vue";

export default {
    name: "t-order-detailed",
    components: {
        Swiper,
        SwiperSlide,
        PopupAuth,
        WriteTicoins,
        //PopupPromocode,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, A11y],
        };
    },
    data() {
        return {
            text_mark: `После заказа выход\n из профиля будет\n автоматический`,
            isWriteTicoins: false,
            promocodeInput: "",
            promocodeValidation: true,
            lettersRu: {
                letter1: [
                    { id: 1, char: `й` },
                    { id: 2, char: `ц` },
                    { id: 3, char: `у` },
                    { id: 4, char: `к` },
                    { id: 5, char: `е` },
                    { id: 6, char: `н` },
                    { id: 7, char: `г` },
                    { id: 8, char: `ш` },
                    { id: 9, char: `щ` },
                    { id: 10, char: `з` },
                    { id: 11, char: `х` },
                    { id: 12, char: `ъ` },
                ],
                letter2: [
                    { id: 1, char: `ф` },
                    { id: 2, char: `ы` },
                    { id: 3, char: `в` },
                    { id: 4, char: `а` },
                    { id: 5, char: `п` },
                    { id: 6, char: `р` },
                    { id: 7, char: `о` },
                    { id: 8, char: `л` },
                    { id: 9, char: `д` },
                    { id: 10, char: `ж` },
                    { id: 11, char: `э` },
                ],
                letter3: [
                    { id: 1, char: `я` },
                    { id: 2, char: `ч` },
                    { id: 3, char: `с` },
                    { id: 4, char: `м` },
                    { id: 5, char: `и` },
                    { id: 6, char: `т` },
                    { id: 7, char: `ь` },
                    { id: 8, char: `б` },
                    { id: 9, char: `ю` },
                    { id: 10, char: `_` },
                ],
            },
            lettersEng: {
                letter1: [
                    { id: 1, char: `a` },
                    { id: 2, char: `b` },
                    { id: 3, char: `c` },
                    { id: 4, char: `d` },
                    { id: 5, char: `e` },
                    { id: 6, char: `f` },
                    { id: 7, char: `g` },
                    { id: 8, char: `h` },
                    { id: 9, char: `i` },
                    { id: 10, char: `j` },
                    { id: 11, char: `k` },
                    { id: 12, char: `l` },
                ],
                letter2: [
                    { id: 1, char: `m` },
                    { id: 2, char: `n` },
                    { id: 3, char: `o` },
                    { id: 4, char: `p` },
                    { id: 5, char: `q` },
                    { id: 6, char: `r` },
                    { id: 7, char: `s` },
                    { id: 8, char: `t` },
                    { id: 9, char: `u` },
                    { id: 10, char: `v` },
                    { id: 11, char: `w` },
                ],
                letter3: [
                    { id: 1, char: `x` },
                    { id: 2, char: `y` },
                    { id: 3, char: `z` },
                    { id: 4, char: `_` },
                ],
            },
            lang: "ENG",
            isWaiting: false,
            sec: 0,
            countSec: 25,
            timerInt: null,
            isPopupPromocode: false,
            isPromocode: false,
            isPopupAuth: false,
            orderId: localStorage.getItem("orderId"),
            orderObj: { items: [] },
            recommendedProducts: [],
            isPopupKeyboad: false,
            isPopupPhone: false,
            deduct_points: `Накопить | Списать баллы`,
            discount_error: "",
            message: "",
            success: `Неверный промокод! Попробуйте ещё раз!`,
            isPopupSuccess: false,
            letters: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
                { id: 13, char: `ё` },
                { id: 14, char: `ф` },
                { id: 15, char: `ы` },
                { id: 16, char: `в` },
                { id: 17, char: `а` },
                { id: 18, char: `п` },
                { id: 19, char: `р` },
                { id: 20, char: `о` },
                { id: 21, char: `л` },
                { id: 22, char: `д` },
                { id: 23, char: `ж` },
                { id: 24, char: `э` },
                { id: 25, char: `я` },
                { id: 26, char: `ч` },
                { id: 27, char: `с` },
                { id: 28, char: `м` },
                { id: 29, char: `и` },
                { id: 31, char: `т` },
                { id: 32, char: `ь` },
                { id: 33, char: `б` },
                { id: 34, char: `ю` },
                { id: 35, char: `_` },
            ],
            discount_img: `discount.png`,
            title: `Хотите получить блюда\n с 50% скидкой?`,
            items: null,
            amount: "",
            promocode: {
                active: false,
                code: "",
                amount: null,
                description: null,
                validation: true,
                error: null,
                type: null,
            },
            nophoto: "nophoto.jpg",
            //chopsticks: 1,
            close: "close.png",
            userName: localStorage.getItem("name"),
            token: localStorage.getItem("token"),
            timerEmptyCart: null,
            isErrorPromo: false,
            delivery_type: "pickup_on_tray",
            now: new Date(),
            now2: new Date(),
            fast_sets: "",
            shop_id: 2,
            bonuses: 0,
            messagePromo: localStorage.getItem("message") ?? "",
            fullPrice: localStorage.getItem("full_price") ?? "",
            write_ticoins: localStorage.getItem("write_ticoins"),
        };
    },
    methods: {
        exitProfile() {
            localStorage.removeItem("token");
            localStorage.removeItem("tel");
            window.location.reload();
        },
        cancelWriteTicoins() {
            axios
                .post(
                    `https://tcode.online/api/${this.cafe_slug}/bonuses/cancel/`,
                    {
                        order_id: this.orderId,
                    },
                    {
                        headers: {
                            Authorization: "Token " + this.token,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    localStorage.removeItem("write_ticoins");
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        changeLang() {
            this.lang === "ENG" ? (this.lang = "РУС") : (this.lang = "ENG");
        },
        promocodeLetter() {
            if (event.target.className !== "keyboard__row") {
                let key = event.target.innerHTML || event.target.className;
                if (key === "clear") {
                    this.promocodeInput = this.promocodeInput.substr(0, this.promocodeInput.length - 1);
                    this.$emit("resetPromocode");
                } else if (key === "Очистить") {
                    this.promocodeInput = "";
                    this.isErrorPromo = false;
                    this.$emit("resetPromocode");
                } else if (key === "space") this.promocodeInput += " ";
                else if (key === "ENG" || key === "РУС") this.changeLang;
                else this.promocodeInput += key.toUpperCase();
            }
        },
        // decreaseCounts() {
        //     this.chopsticks--;
        //     if (this.chopsticks <script 1) this.chopsticks = 1;
        // },
        // increaseCounts() {
        //     this.chopsticks++;
        // },
        closeAuth() {
            this.isPopupAuth = false;
        },
        cancelClose() {
            this.isPopupKeyboad = false;
            this.message = "";
        },
        showPrice: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    console.log(item.price, item.quantity, item.price * item.quantity);
                    output = item.product.price * item.quantity;
                }
            });
            return output;
        },
        getOrderItem: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.id;
                }
            });
            return output;
        },
        addToOrder(order_id, product_id) {
            let self = this;
            axios
                .post(api + `order-add/`, {
                    order_id: order_id,
                    product_id: product_id,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        changeOrderItem(order_id, order_item_id, type) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id: order_id,
                    order_item_id: order_item_id,
                    type: type,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        typeLetter() {
            if (event.target.className !== "phone-rows" && event.target.className !== "btnApply") {
                let key = event.target.innerHTML || event.target.className;
                if (key === "clear") this.message = this.message.substr(0, this.message.length - 1);
                else if (key === "Очистить") this.message = "";
                else if (key === "space") this.message += " ";
                else this.message += key.toUpperCase();
            }
        },
        timerWaiting() {
            this.timerInt = setInterval(() => {
                if (this.sec > this.countSec) {
                    this.sec = 0;
                    this.isWaiting = true;
                }
                if (this.isWaiting && this.sec >= this.countSec) {
                    this.$router.push("/");
                }
                console.log("Past seconds: " + this.sec++);
            }, 1000);
        },
        /* проверка промокода */
        applyPromo() {
            this.isPopupPromocode = true;
            this.promocodeInput = "";
            this.isErrorPromo = false;
        },
        applyPromocode(promocodeInput) {
            this.promocode.code = promocodeInput;
            this.checkDiscount();
        },
        resetPromocode() {
            this.promocode.validation = true;
            this.promocode.error = "";
        },
        deletePromo() {
            if (this.promocode.type === "Product") {
                console.log(this.orderObj.items);
                for (let i = 0; i < this.orderObj.items.length; i++) {
                    console.log(this.orderObj.items[i]);
                    if (this.orderObj.items[i].type === "discounted") {
                        this.orderObj.items.splice(i, 1);
                        localStorage.setItem("cart", JSON.stringify(this.orderObj.items));
                    }
                }
            }
            axios
                .post(`https://tcode.online/api/${this.cafe_slug}/promocodes/cancel/`, {
                    order_id: this.orderId,
                })
                .then((response) => {
                    console.log(response.data);
                    localStorage.removeItem("promocode");
                    this.fullPrice = "";
                    this.messagePromo = "";
                    localStorage.removeItem("full_price");
                    localStorage.removeItem("message");
                    this.isPromocode = false;
                    this.promocode.active = false;
                    this.promocode.code = null;
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        checkDiscount() {
            // В ответе приходит message - выводим текст о примененных баллах
            axios
                .post(
                    `https://tcode.online/api/${this.cafe_slug}/promocodes/set/`,
                    {
                        order_id: this.orderId,
                        discount_slug: this.promocode.code,
                    },
                    {
                        headers: {
                            Authorization: "Token " + this.token,
                        },
                    }
                )
                .then((response) => {
                    console.log(response.data);
                    this.promocode.active = true;
                    this.applyDiscount(response.data);
                    this.isPromocode = true;
                    this.isErrorPromo = false;
                    this.messagePromo = response.data.message;
                    this.fullPrice = response.data.order_obj.full_price;
                    localStorage.setItem("full_price", this.fullPrice);
                    localStorage.setItem("message", this.messagePromo);
                    this.isPopupPromocode = false;
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    this.promocode.validation = false;
                    this.promocode.error = error.response?.data.error;
                    this.isErrorPromo = true;
                    this.messagePromo = "";
                });
        },
        applyDiscount(data) {
            this.promocode.description = data.description;
            this.promocode.type = data.type;
            this.promocode.discount_amount = data.discount_amount;
            if (data.type === "Product") {
                const elem = this.orderObj.items.find((item) => item.id === data.product.id);
                if (!elem) {
                    data.product.name = data.product.real_name;
                    data.product.type = "discounted";
                    data.product.this.orderObj.items.push(data.product);
                    localStorage.setItem("cart", JSON.stringify(this.orderObj.items));
                    this.priceCart();
                }
            }
            localStorage.setItem("promocode", JSON.stringify(this.promocode));
        },
        checkPromocode() {
            let promocode = JSON.parse(localStorage.getItem("promocode")) ?? null;

            if (promocode) {
                this.promocode = promocode;
            }
        },
        sendOrder() {
            this.$router.push("/pay");
        },
    },
    created() {
        if (!this.orderId) {
            this.$router.push("/");
        }
        let self = this;
        axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
            self.orderObj = response.data;
        });
        axios
            .get(api + `recommended-in-cart/?order_id=` + this.orderId, {
                headers: {
                    "Accept-Language": localStorage.getItem("lang"),
                },
            })
            .then(function (response) {
                self.recommendedProducts = response.data;
            });
        this.discount_error = "";
    },
    computed: {
        lettersLang() {
            return this.lang === "ENG" ? this.lettersRu : this.lettersEng;
        },
        totalCountCart() {
            if (!this.orderObj.items) return [];
            return this.orderObj.items.reduce((acc, item) => acc + item.quantity, 0);
        },
    },
    watch: {
        totalCountCart(value) {
            console.log(value);
            if (value === 0) {
                this.timerEmptyCart = setInterval(() => {
                    if (this.sec >= 20) {
                        this.$router.push("/");
                    }
                    console.log("Wait times: " + this.sec++);
                }, 1000);
            }
        },
    },
    mounted() {
        this.timerWaiting();
        this.checkPromocode();
        console.log(this.promocode);
        this.now.setTime(this.now.getTime() + 0 * 60 * 1000);
        this.now2.setTime(this.now.getTime() + 10 * 60 * 1000);
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "pushkina";
        }
        console.log(this.cafe_slug);
        if (!this.userName || !this.token) {
            this.isPopupAuth = true;
        }
        if (this.token) {
            axios
                .get(`https://tcode.online/api/${this.cafe_slug}/bonuses/`, {
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.bonuses = response.data.bonuses;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        axios
            .get(api)
            .then((response) => {
                console.log(response.data);
                this.shop_id = response.data.id;
                localStorage.setItem("shop_id", this.shop_id);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    unmounted() {
        clearInterval(this.timerInt);
        clearInterval(this.timerEmptyCart);
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
@import "~@/assets/scss/keyboards";
@import "~@/assets/scss/auth";
.write_off_value {
    color: #d66c56;
    margin-right: 5px;
}
.cart-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 30px;
    &__body {
        flex: 1 1 auto;
    }
}
.block-rows {
    display: flex;
    flex-direction: column;
    background-color: #f8f9f8;
    padding-bottom: 30px;
    ._container {
        width: 100%;
    }
}

.block-order__title p {
    width: 100%;
}

.block-apply {
    display: flex;
    padding: 0 38px;
    margin: 0 -1rem;

    .apply-promo {
        width: 100%;
        height: 70px;
        margin: 2rem 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $ms3) {
            height: 50px;
        }
        @media (max-width: $ms4) {
            margin-top: 1rem;
        }
    }
}

.to-catalog {
    width: fit-content;
    margin: 1rem auto;
    padding: 10px 2rem;
}

.block-orders {
    padding: 50px 0 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 50vh;
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 40px;
        @media (max-width: $ms3) {
            margin-top: 20px;
        }
    }
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }

    @media (max-width: $ms3) {
        padding: 0 15px;
    }
    @media (max-width: $ms4) {
        padding: 0 10px;
    }
}

.order-detailed {
    position: relative;
    background: none;
    &__promocode {
        @media (max-width: $ms3) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__title {
        text-align: center;
        color: #000;
        @include adaptive-font(50, 18, 0);
        @include adaptive-value("line-height", 51, 20, 0);
    }

    &__promocode {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        @media (max-width: $ms3) {
            margin: 20px auto 0;
        }

        .btn {
            flex: 1 1 50%;
            height: 70px;
            display: flex;
            justify-content: center;
            margin: 45px 15px 0;
            @media (max-width: $ms3) {
                width: 100%;
                height: 50px;
                margin: 20px 15px 0;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.block-flex {
    display: flex;
    align-items: center;
    .text_mark {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #e4f9f3;
        padding: 0 20px;
        opacity: 0.5;
        white-space: pre-line;
    }
}
.block-side {
    .img-coin {
        position: absolute;
        width: 150px;
        height: 150px;
        &.coin1 {
            top: -3rem;
            right: -3rem;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            filter: blur(2px);
        }
        &.coin2 {
            bottom: -4rem;
            right: 2rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.block-side .t-coin__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: #fff;
    position: relative;
    z-index: 1;
}
.block-side .t-coin__btn {
    height: 54px;
    flex: 0 0 auto;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    margin-top: 0;
    position: relative;
    z-index: 1;
}
.block-side .t-coin__btn.error {
    color: #d66c56;
}
.t-coin.block-profile .t-coin__title {
    margin-top: -2rem;
}
.t-coin.block-profile .t-coin__subtite {
    font-size: 28px;
    font-weight: 700;
    margin-top: 1rem;
}
.t-coin.block-profile {
    .img-coin.coin1 {
        top: -4rem;
        right: 3rem;
    }
    .img-coin.coin2 {
        top: 0;
        bottom: 0;
        right: -2.5rem;
        margin: auto;
    }
}
.block-side .t-coin__subtite {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
}
.block-side .t-coin__btn.btn-exit {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    &:hover,
    &:focus {
        background: #fff;
        color: #28735f;
        transition: 0.2s ease 0.2s;
    }
}
.block-side .t-coin__items {
    .t-coin__item {
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        color: #fff;
        margin-top: 10px;
        display: flex;
        .balls {
            display: flex;
            align-items: center;
            margin-left: 15px;
            img {
                width: 17px;
                height: 15px;
            }
        }
    }
}
.keyboard__input-block {
    position: relative;
    .error {
        position: absolute;
        right: 2rem;
        svg {
            margin-left: 5px;
        }
    }
}
.popupKeyboad {
    .keyboard__input-promo {
        input {
            background: none;
        }
    }
    .title-input-message {
        font-size: 32px;
        text-align: center;
        padding: 50px 0;
    }
    .btn-continue {
        background: #28735f;
        margin: 20px auto;
        padding: 20px 30px;
        color: #fff;
    }
}
.block-side .t-coin__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.block-side .t-coin {
    background-color: #28735f;
    height: 100%;
}
.block-side .stroke {
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .cart-page {
        overflow-x: hidden;
    }
    .block-order {
        flex-direction: column;
        height: auto;
    }
    .block-order__img {
        min-width: unset;
        max-width: unset;
    }
    .block-order__body {
        padding: 15px;
        flex-direction: column;
    }
    .block-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        .block-order__weight {
            margin-top: 0;
        }
        .block-order__price {
            display: flex;
            justify-content: flex-end;
        }
    }
    .block-order__buttons {
        min-width: 100%;
        max-width: 100%;
        .counter,
        .btn {
            margin-top: 1rem;
        }
    }
    .count-tools {
        padding: 15px;
        .counter {
            width: 100%;
            margin-top: 1rem;
        }
    }
    .count-tools__name {
        font-size: 14px;
        line-height: 20px;
    }
    .count-tools__container {
        flex-direction: column;
    }
    .slider__title {
        font-size: 18px;
        line-height: 20px;
    }
    .slider__body {
        padding: 10px;
    }
    .block-side {
        background-image: none;
    }
    .block-side__container {
        flex-direction: column;
    }
    .block-side__block:not(:first-child) {
        margin-left: 0;
    }
    .block-side .t-coin {
        padding: 15px;
    }
    .block-side .t-coin__btn {
        width: 100%;
        height: 50px;
    }
    .block-side .stroke,
    .block-form {
        margin-top: 1rem;
    }
    .total-sum__price {
        flex-direction: column;
    }
    .total-sum__price,
    .total-sum__discount {
        font-size: 20px;
        line-height: 30px;
    }
    .slider__img {
        max-width: 100px;
    }
    .slider__block {
        width: 100% !important;
        min-height: 100px;
        max-height: 100px;
    }
    .slider__name {
        font-size: 14px;
        line-height: 20px;
    }
    .slider {
        .counter {
            width: 100%;
            height: 40px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .counter__minus,
    .counter__plus {
        width: 40px;
        height: 40px;
    }
    .slider .add-to-cart {
        width: 40px;
        height: 40px;
    }
    .card-dish__change {
        margin: 5px 0px 0px 0px;
    }
    .popupKeyboad .keyboard__input-promo input,
    .popupKeyboad .keyboard__input-promo button {
        font-size: 18px;
        line-height: 20px;
    }
    .popupKeyboad .keyboard__input-promo {
        height: auto;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        flex-direction: column;
    }
    .keyboard__input-block {
        height: 50px;
        padding: 0 1rem;
    }
}
</style>
