<template>
    <ul class="menu">
        <li v-for="item in items" :key="item.id">
            <a :href="item.path" class="menu__item" :class="item.real_name === `Новинки` ? `btn-new` : false">{{
                item.real_name
            }}</a>
        </li>
    </ul>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
    name: "t-menu-items",
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        axios
            .get(api + `categories/`, {
                headers: {
                    "Accept-Language": localStorage.getItem("lang"),
                },
            })
            .then((response) => {
                this.items = response.data;
            })
            .catch((e) => {
                this.errors.cafes = e.message;
            });
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vars";

.menu {
    margin-top: 42px;
    @media (max-width: $ms3) {
        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 15px;
    }

    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }

    @media (max-width: $ms3) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    li {
        margin-top: 20px;
        @media (max-width: $ms3) {
            flex: 0 0 48%;
            margin-top: 1rem;
        }
    }

    &__item {
        border: 1px solid #28735f;
        border-radius: 10px;
        font-size: 30px;
        line-height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #28735f;
        width: 236px;
        height: 50px;
        @media (max-width: $ms3) {
            width: 100%;
            font-size: 16px;
            line-height: 20px;
        }

        &:hover,
        &:focus {
            background: #28735f;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
    }

    .active {
        background: #28735f;
        color: #fff;
    }
    .btn-new {
        background: $red;
        color: #fff;
        &:hover,
        &:focus {
            background: #d44927;
            transition: 0.2s ease 0.2s;
        }
    }
}
</style>
